import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

export function fetchPLCs(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/plcs`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_PLCS_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.FETCH_PLC_ERROR, payload: error });
      return error;
    }
  };
}

export function fetchPLC(id) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/plcs/${id}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_PLC_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_PLC_ERROR, payload: error });
    }
  };
}
