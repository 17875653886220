import { ActionTypes } from '../actions/Shared';

const initialState = {
  trackerEquipment: null,
  defenders: null,
  autoHydrantError: null,
};

// return { ...state, equipment: action.payload, allEquipment: { ...state.allEquipment, [action.payload.id]: action.payload } };
const TrackerEquipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_TRACKER_EQUIPMENT_SUCCESS:
      return { ...state, trackerEquipment: action.payload };
    case ActionTypes.FETCH_ALL_DEFENDERS_SUCCESS:
      return { ...state, defenders: action.payload };
    case ActionTypes.ADD_AUTO_HYDRANT_SUCCESS:
      // Right now we are just waiting for the trails route to update itself with the 1 second interval
      // we probably want to fix this for speed and create a more complicated reducer.
      // Will also need to update action to return more than just the status.
      return { ...state, autoHydrantError: action.payload };
    case ActionTypes.DELETE_AUTO_HYDRANT_SUCCESS:
      // Right now we are just waiting for the trails route to update itself with the 1 second interval
      // we probably want to fix this for speed and create a more complicated reducer.
      // Will also need to update action to return more than just the status.
      return { ...state, autoHydrantError: action.payload };
    case ActionTypes.AUTO_HYDRANT_ERROR:
      return { ...state, autoHydrantError: action.payload };
    case ActionTypes.FETCH_TRACKER_EQUIPMENT_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default TrackerEquipmentReducer;
