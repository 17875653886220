import React, { useEffect, useContext, useState } from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { NavLink, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Languages, LanguageContext } from 'isno/lib/languages/LanguageContext';
import {
  FaCog,
  FaSquare,
  FaInfoCircle,
  FaSignOutAlt,
  FaChevronLeft, FaChevronRight,
} from 'react-icons/fa';

import { StatMonitor, Version } from 'isno/lib/components/displays';
import logo from '../static/glenEdenImage.svg';
import {
  fetchNavBarStats,
  fetchSettings,
  signoutUser,
  setNavState,
} from '../actions';

const NavBarSide = (props) => {
  const theme = useTheme();
  const styles = stylesFromTheme(theme, props);
  const { language, setLanguage } = useContext(LanguageContext);

  const [languageSetupDone, setLanguageSetupDone] = useState(false);

  // If we succesfully authenticated: fetch user settings
  useEffect(() => {
    if (!props.settings?.settings) {
      props.fetchSettings();
    }
  }, []);

  // If we succesfully fetched user settings: set current language
  useEffect(() => {
    if (props.settings?.settings?.language && !languageSetupDone) {
      setLanguage(Languages[props.settings?.settings?.language]);
      // We only want to set our language here the first time (i.e on setup).
      setLanguageSetupDone(true);
    }
  }, [props.settings?.settings]);

  let fiveSecondIntervalId;
  const fiveSecondInterval = () => {
    fiveSecondIntervalId = setTimeout(async () => {
      await props.fetchNavBarStats();
      fiveSecondIntervalId = setTimeout(fiveSecondInterval, 5000);
    }, 5000);
  };

  useEffect(() => {
    props.fetchNavBarStats();
    fiveSecondInterval();
    return () => {
      clearTimeout(fiveSecondIntervalId);
    };
  }, []);

  return (
    <div id="sidebar" css={[styles.container, props.navbarClosed ? styles.closed : styles.open]}>
      <button
        type="button"
        css={styles.hamburger}
        onClick={() => {
          props.setNavState(!props.navbarClosed);
          window.dispatchEvent(new Event('resize'));
        }}
      >
        {props.navbarClosed
          ? <FaChevronRight />
          : <FaChevronLeft />}
      </button>
      <div css={[styles.content, props.navbarClosed ? styles.hidden : {}]}>

        <div css={styles.mountainInfo}>
          <img alt="iSno" src={logo} css={styles.logoMountain} />
        </div>

        <StatMonitor navbarStatistics={props.navbarStatistics} />

        <div css={styles.navItems}>
          <div css={[styles.navItemsTopGroup, styles.hideScrollbar]}>
            <Link
              exact
              to="/overview"
              color={theme.linkColors.pumps}
              activeStyle={{
                color: theme.linkColors.pumps,
              }}
            >
              <NavItem>
                <Route exact
                  path="/overview"
                  component={() => (
                    <FaSquare css={styles.faNavIcons} />
                  )}
                />
                <NavItemText>{language.overview}</NavItemText>
              </NavItem>
            </Link>
            <Link
              exact
              to="/tracker"
              color={theme.linkColors.tracker}
              activeStyle={{ color: theme.linkColors.tracker }}
            >
              <NavItem>
                <Route exact
                  path="/tracker"
                  component={() => (
                    <FaSquare css={styles.faNavIcons} />
                  )}
                />
                <NavItemText>{language.tracker}</NavItemText>
              </NavItem>
            </Link>
            <Link
              exact
              to="/equipment"
              color={theme.linkColors.pumps}
              activeStyle={{
                color: theme.linkColors.pumps,
              }}
            >
              <NavItem>
                <Route exact
                  path="/equipment"
                  component={() => (
                    <FaSquare css={styles.faNavIcons} />
                  )}
                />
                <NavItemText>{language.equipment}</NavItemText>
              </NavItem>
            </Link>
            <Link
              exact
              to="/trends"
              color={theme.linkColors.trends}
              activeStyle={{ color: theme.linkColors.trends }}
            >
              <NavItem>
                <Route exact
                  path="/trends"
                  component={() => (
                    <FaSquare css={styles.faNavIcons} />
                  )}
                />
                <NavItemText>{language.trends}</NavItemText>
              </NavItem>
            </Link>
            <Link
              exact
              to="/reports"
              color={theme.linkColors.reports}
              activeStyle={{ color: theme.linkColors.reports }}
            >
              <NavItem>
                <Route exact
                  path="/reports"
                  component={() => (
                    <FaSquare css={styles.faNavIcons} />
                  )}
                />
                <NavItemText>{language.reports}</NavItemText>
              </NavItem>
            </Link>
          </div>
          <div css={styles.navItemsBottomGroup}>
            <div css={styles.horzDivider} />
            <Link
              exact
              to="/settings"
              color={theme.linkColors.settings}
              activeStyle={{ color: theme.linkColors.settings }}
            >
              <NavItem>
                <FaCog />
                <NavItemText>{language.settings}</NavItemText>
              </NavItem>
            </Link>
            <ExternalLink
              href={language.googleDocsLink}
              target="_blank"
              rel="noreferrer"
              color={theme.linkColors.settings}
            >
              <NavItem>
                <FaInfoCircle />
                <NavItemText>{language.userGuide}</NavItemText>
              </NavItem>
            </ExternalLink>
            <Link
              exact
              to="/"
              onClick={() => { props.signoutUser(); }}
              color={theme.linkColors.logout}
            >
              <NavItem>
                <FaSignOutAlt />
                <NavItemText>{language.logout}</NavItemText>
              </NavItem>
            </Link>
            <div css={styles.navItemsVersion}>
              <Version />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

const stylesFromTheme = (theme, props) => {
  return {
    container: css`
      z-index: 5;
      background-color: ${theme.navBar};
      height: 100vh;
      flex: 0 0 160px;
      display: flex;
      flex-direction: column;
      position: ${props?.float ? 'fixed' : 'sticky'};
      top:0;
      left:0;

      transition: flex-basis ease 0.4s, width ease 0.4s; 
      > *:not(:first-of-type) {
        transition: opacity 0.4s ease; 
      }

      // using flex-basis not width
      width: 0;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        position: fixed;
        height: 100%;
      }
    `,
    closed: css`
      flex-basis: 16px; 
      > *:not(:first-of-type) {
        opacity: 0;
        pointer-events: none; 
      }
      width: ${props?.float ? '16px' : ''};
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        width: 16px;
      }
    `,
    open: css`
      width: ${props?.float ? '160px' : ''};
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        width: 160px;
      }
    `,
    hamburger: css`
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      box-shadow: 0 0 4px black;

      background: white;
      width: 24px;
      height: 24px;
      border-radius: 12px;

      position: absolute;
      right: -8px;
      top: 55px;
      transition: background-color 0.2s, color 0.2s, border-color: 0.2; 
      :hover{
        box-shadow: 0 0 6px black;
      }
    `,
    content: css`
      flex:1;
      // overflow-y: auto;  /*adds scroll to this container*/
      overflow: hidden;
      display: flex;
      flex-direction:column;
    `,
    mountainInfo: css`
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
    `,
    logoMountain: css`
      height: auto;
      width: 85%;
      margin: auto;
    `,
    mountainText: css`
      color: white;
      padding-left: 10px;
      font-size: 110%;
    `,
    navItems: css`
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `,
    navItemsTopGroup: css`
      flex:1;
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 100px - 86px - 170px); // heights of our other navbar items
      overflow-y: scroll;
      overfloy-x: hidden;
  `,
    navItemsVersion: css`
      flex:1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 10px;
  `,
    navItemsBottomGroup: css`
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
  `,
    faNavIcons: css`
      transform: rotate(45deg);
  `,
    hidden: css`
      display: none;
  `,
    hideScrollbar: css`
      overflow-y: scroll;
      // -ms-overflow-style: none;  /* Internet Explorer 10+ */
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
      }
      scrollbar-width: none; /* Firefox */
  `,
    horzDivider: css`
      height: 1px;
      width: 100%;
      background: white;
      margin: 1px 0px;
      padding: 0px 10px;
  `,
  };
};

const Link = styled(NavLink)`
  color: white;
  padding: 12px 0px;
  :not(:last-child) {
    border-bottom: 1px solid #00000030;
  }

  :hover {
    color: ${(props) => props.color}
  }
`;

const ExternalLink = styled.a`
  color: white;
  padding: 12px 0px;
  :not(:last-child) {
    border-bottom: 1px solid #00000030;
  }

  :hover {
    color: ${(props) => props.color}
  }
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
`;

const NavItemText = styled.p`
  margin-left: 10px;
`;

NavBarSide.propTypes = {
  signoutUser: PropTypes.func.isRequired,
  setNavState: PropTypes.func.isRequired,
  navbarClosed: PropTypes.bool.isRequired,

  navbarStatistics: PropTypes.shape({
    gunsOn: PropTypes.number,
    gunsWaiting: PropTypes.number,
    gpm: PropTypes.number,
    cfm: PropTypes.number,
  }).isRequired,

  fetchNavBarStats: PropTypes.func.isRequired,

  auth: PropTypes.shape({
    user: PropTypes.shape({}),
    authenticated: PropTypes.bool,
    need2fa: PropTypes.bool,
    error: PropTypes.number,
  }).isRequired,

  settings: PropTypes.shape({
    settings: PropTypes.shape({
      userId: PropTypes.number,
      theme: PropTypes.string,
      language: PropTypes.string,
      seasonStart: PropTypes.string,
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,

  fetchSettings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  navbarStatistics: state.nav.navbarStatistics,
  settings: state.settings,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  signoutUser, setNavState, fetchNavBarStats, fetchSettings,
})(NavBarSide);
