// Login Screen v0.0.1
// Created By Trevor Colby
// IMPORTANT: This file should be identical for every mountain
// any configuration should occur in './Configuration.js'.
// When updating do all development and testing on isno-www (Quebec Factory)
// and then update the version (v0.x.x) in that before updating this screen.
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { useLocation, useHistory } from 'react-router-dom';
import background from 'isno/lib/static/images/HKD_Login_Background_Comp.jpg';
import logo from 'isno/lib/static/images/iSnoLogo.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { signinUser, getCurrentAuth } from '../actions';
import LoginButton from '../components/Loginbutton';
import configuration from './Configuration';
// Configuration values pulled in from the './Configuration.js' file
const { initialScreen } = configuration;

const LoginScreen = (props) => {
  const theme = useTheme();
  const styles = stylesFromTheme(theme);
  const location = useLocation();
  const history = useHistory();
  const { language } = useContext(LanguageContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [firstTime, setFirstTime] = useState(() => {
    return 0;
  });

  const { from } = location.state || { from: { pathname: initialScreen } };

  useEffect(() => {
    props.getCurrentAuth();
  }, []);

  // If we fail to authenticate but don't receive an error: communication issue
  useEffect(() => {
    if (firstTime > 0 && props.auth.authenticated === false && props.auth.error === undefined) {
      setErrorMessage('Looks like there is a communication error at this time. Please check your connection and try again.');
    }
  }, [props.auth]);

  // If we fail to authenticate and receive an error: display error
  useEffect(() => {
    // if (!firstTime) {
    if ([400, 401].indexOf(props.auth.error) > -1) {
      // setFirstTime(firstTime + 1);
      setErrorMessage(`Invalid Credentials\nCheck Username/Password\n(HTTPS ERROR ${props.auth.error})`);
    } else if ([404, 405, 408].indexOf(props.auth.error) > -1) {
      // setFirstTime(firstTime + 1);
      setErrorMessage(`Communication Failure\n(HTTPS Error ${props.auth.error})`);
    }
    // }
  }, [props.auth.error]);

  useEffect(() => {
    if (!props.auth.need2fa) {
      setTwoFactorCode('');
    }

    if (props.auth.authenticated) {
      history.push(from.pathname);
    }
  }, [props]);

  const loginAttempt = async (e) => {
    setFirstTime(firstTime + 1);
    setLoading(true);
    e.preventDefault();
    try {
      await props.signinUser({ username, password, twoFactorCode });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const renderForm = () => {
    if (props.auth.need2fa) {
      const { qrCodeUrl } = props.auth.user;
      return (
        <form css={styles.form}>
          <img css={styles.logo} src={logo} alt="iSno_Logo" />
          {qrCodeUrl
          && (
          <div css={styles.qrCodeContainer}>
            <img src={qrCodeUrl} alt="QR Code" css={styles.qrCode} />
            <div css={styles.alert}>{language.dualFAIndicator}</div>
          </div>
          )}
          <input css={styles.login_input_text}
            autoComplete="one-time-code"
            placeholder="Enter 2 Factor Code"
            maxLength={6}
            value={twoFactorCode}
            onChange={(event) => {
              setTwoFactorCode(event.target.value);
            }}
          />
          <button css={styles.noFocus} type="submit" onClick={(e) => { loginAttempt(e); }}>
            <LoginButton animate={loading} authenticationText={language.authenticate} />
          </button>
        </form>
      );
    }

    return (
      <form css={styles.form}>
        <img css={styles.logo} src={logo} alt="iSno_Logo" />
        <input css={styles.login_input_text}
          autoComplete="username"
          placeholder={language.enterUsername}
          value={username}
          onChange={(event) => {
            setUsername(event.target.value);
          }}
        />
        <input css={styles.login_input_password}
          autoComplete="current-password"
          placeholder={language.enterPassword}
          type="password"
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        />
        <button css={styles.noFocus} type="submit" onClick={(e) => { loginAttempt(e); }}>
          <LoginButton animate={loading} authenticationText={language.authenticate} />
        </button>
        {
          firstTime > 0 && !loading
        && <div css={styles.error}>{errorMessage}</div>
        }
      </form>
    );
  };

  return (
    <div css={styles.wrapper}>
      <img css={styles.backgroundImage} src={background} alt="Background_Img" />
      {renderForm()}
    </div>
  );
};

const stylesFromTheme = (theme) => {
  return {
    wrapper: css`
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `,
    backgroundImage: css`
      z-index: -1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: fixed;
      top: 0px;
      left: 0px;
      overflow: hidden;
      filter: brightness(40%);
    `,
    form: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 320px;
      margin-bottom: 5%;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        font-size: 16px;
      }
    `,
    logo: css`
      width: 200px;
    `,
    error: css`
      width: 200px;
      text-align: center;
      white-space: pre-line;
      color: white;
    `,
    noFocus: css`
      stroke: white;
      :focus {
        outline: none;
        stroke: #59CBEE;
      }
    `,
    qrCode: css`
      border-radius: 6px;
      width: 150px;
      margin-top: 20px;
      margin-bottom: 10px;
    `,
    qrCodeContainer: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `,
    alert: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      //position: fixed;
      //top: 0;
      //text-align: center;
      color: white;
      background: red;
      height: 40px;
      padding: 20px;
      margin-top: 20px;
      border-radius: 4px;
      color: black;
    `,
    loginButton: css`
        display: block;
        width: 100%;
        text-align: center;
        /* background-color: #505052; */
        background-color: rgba(80, 80, 82, 0);
        color: white;
        padding: 14px 20px;
        margin: 8px 0;
        cursor: pointer;
        border: 1px solid rgba(231, 231, 231, 1);
        border-radius: 20px;
        :focus {
          outline: 0;
          border: thin solid #59CBEE !Important;
        };
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: white;
          opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: white;
        }
        ::-ms-input-placeholder { /* Microsoft Edge */
          color: white;
        }
        :hover {
          /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 3px 10px 0 rgba(0,0,0,0.19); */
          border-color: #59CBEE;
          background-color: rgba(80, 80, 82, 0.4);
        }
    `,
    login_input_text: css`
        width: 100%;
        padding: 12px 20px; /*pading inside entry*/
        border-bottom: 1px solid ;
        margin: 20px 0 10px;
        display: inline-block;
        border: none;
        border-bottom: thin solid rgba(231, 231, 231, 1)!important;
        box-sizing: border-box;
        background:rgba(255,255,255, 0.0);
        color: white;
        :focus {
          outline: 0;
          border-bottom: thin solid #59CBEE !Important;
        };
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: white;
        opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: white;
        }
        ::-ms-input-placeholder { /* Microsoft Edge */
          color: white;
        }
    `,
    login_input_password: css`
        width: 100%;
        padding: 12px 20px; /*pading inside entry*/
        margin: 10px 0 30px;
        display: inline-block;
        border: none;
        border-bottom: thin solid rgba(231, 231, 231, 1) !important;
        box-sizing: border-box;
        background:rgba(255,255,255, 0.0);
        color: white;
        :focus {
          outline: 0;
          border-bottom: thin solid #59CBEE !Important;
        };
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: white;
        opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: white;
        }
        ::-ms-input-placeholder { /* Microsoft Edge */
          color: white;
        }
    `,
  };
};

LoginScreen.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      qrCodeUrl: PropTypes.string,
    }),
    authenticated: PropTypes.bool,
    need2fa: PropTypes.bool,
    error: PropTypes.number,
    // error: PropTypes.oneOfType([
    //   PropTypes.number,
    // ]),
  }).isRequired,

  signinUser: PropTypes.func.isRequired,
  getCurrentAuth: PropTypes.func.isRequired,

};

const mapStateToProps = (state) => ({
  auth: state.auth,
  settings: state.settings,
});

export default connect(mapStateToProps, { signinUser, getCurrentAuth })(LoginScreen);
