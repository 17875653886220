import { ActionTypes } from '../actions/Shared';

const initialState = {
  flowZones: null,
  temperatureZones: null,
  pressureZones: null,
  zoneIOValues: null,
};

const TrendReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_PRESSURE_ZONES_SUCCESS:
      return { ...state, pressureZones: action.payload };
    case ActionTypes.ADD_ZONE_SUCCESS:
      return {
        ...state,
        [`${action.payload?.type}Zones`]: {
          ...state[`${action.payload?.type}Zones`],
          [action.payload.data.id]: action.payload.data,
        },
      };
    // case ActionTypes.EDIT_ZONE_SUCCESS:
    //   return {
    //     ...state,
    //     [`${action.payload?.type}Zones`]: {
    //       ...state[`${action.payload?.type}Zones`],
    //       [action.payload.data.id]: action.payload.data,
    //     },
    //   };
    case ActionTypes.DELETE_ZONE_SUCCESS:
      return {
        ...state,
        [`${action.payload?.type}Zones`]: {
          ...Object.values(state[`${action.payload?.type}Zones`] || {}).filter((item) => item.id !== action.payload.data.id).reduce((acc, curr) => {
            acc[curr.id] = curr;
            return acc;
          }, {}),
        },
      };
    case ActionTypes.FETCH_TEMPERATURE_ZONES_SUCCESS:
      return { ...state, temperatureZones: action.payload };
    case ActionTypes.FETCH_FLOW_ZONES_SUCCESS:
      return { ...state, flowZones: action.payload };
    case ActionTypes.FETCH_ZONE_IO_VALUES_SUCCESS:
      return { ...state, zoneIOValues: action.payload };
    case ActionTypes.ADD_ZONE_IO_VALUE_SUCCESS:
      return {
        ...state,
        zoneIOValues: {
          ...state.zoneIOValues,
          [action.payload.id]: action.payload,
        },
      };
    case ActionTypes.DELETE_ZONE_IO_VALUE_SUCCESS:
      return {
        ...state,
        zoneIOValues: {
          ...Object.values(state.zoneIOValues).filter((item) => item.id !== action.payload.id).reduce((acc, curr) => {
            acc[curr.id] = curr;
            return acc;
          }, {}),
        },
      };
    case ActionTypes.EDIT_ZONE_IO_VALUE_SUCCESS:
      return {
        ...state,
        zoneIOValues: {
          ...state.zoneIOValues,
          [action.payload.id]: action.payload,
        },
      };
    case ActionTypes.FETCH_ZONES_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default TrendReducer;
