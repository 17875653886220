import React, { useState } from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { withTheme } from 'emotion-theming';
import { Languages, LanguageContext } from 'isno/lib/languages/LanguageContext';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import Routes from '../components/Routes';

const muiTheme = createMuiTheme({
  palette: {
    color: '#0ABCC9',
    textColor: '#0ABCC9',
    selectionColor: '#0ABCC9',
    handleFillColor: '#0ABCC9',
    selectColor: '#0ABCC9',
    selectTextColor: '#0ABCC9',
    calendarYearBackgroundColor: '#0ABCC9',
    headerColor: '#0ABCC9',
    primary: {
      light: '#0ABCC9',
      main: '#028690',
    },
    secondary: {
      light: '#0ABCC9',
      main: '#028690',
    },
  },
});

const App = () => {
  const [language, setLanguage] = useState(Languages.english);
  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={muiTheme}>
          <Router>
            <Routes />
          </Router>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </LanguageContext.Provider>
  );
};

export default withTheme(App);
