import React, { useContext, useState } from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import Select from 'react-select';
import {
  DateTimePicker,
} from '@material-ui/pickers';

import { FaDownload } from 'react-icons/fa';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { LoadingIndicator } from 'isno/lib/components/displays';
import 'react-notifications-component/dist/theme.css';
import { sendReportRequest } from '../actions';

const ReportScreen = (props) => {
  const theme = useTheme();
  const styles = stylesFromTheme(theme);
  const { language } = useContext(LanguageContext);
  const [report, setReport] = useState(0);
  const [startDay, setStartDay] = useState(null);
  const [endDay, setEndDay] = useState(null);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  // const reportOptions = [];

  // const generateReportOptions = (reports) => {
  //   let counter = 0;
  //   reports.forEach((report) => {
  //     reportOptions.push({ value: counter, label: report.name });
  //     counter++;
  //   });
  //   return reportOptions;
  // };

  // Should move to a report generator like above.
  const reportOptions = [ // leave unused values in because they are for other mountains
    { value: 0, label: language.trailReport },
    { value: 1, label: language.snowmakingReport },
    { value: 2, label: language.notesReport },
  ];

  const reportSelectStyles = {
    menuList: (provided, state) => ({
      ...provided,
      zIndex: 2,
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 2,
    }),
  };

  return (
    <div css={styles.background}>
      <LoadingIndicator visible={loading} text={language.initDownload} />
      <h1>{language.reports}</h1>
      <div css={[styles.input, styles.select]}>
        <Select
          options={reportOptions}
          styles={reportSelectStyles}
          defaultValue={{ value: 0, label: language.trailReport }}
          onChange={(selection) => { setReport(selection.value); }}
        />
      </div>
      <div css={[styles.input, styles.calendar]}>
        <div css={css`margin: 10px 5px 0px 5px; display: flex;`}>
          <DateTimePicker
            label={language.startDate}
            ampm={false}
            showTodayButton
            inputVariant="outlined"
            value={startDay}
            onChange={setStartDay}
          />
          <div css={css`width: 5px;`} />
          <DateTimePicker
            label={language.endDate}
            ampm={false}
            showTodayButton
            inputVariant="outlined"
            value={endDay}
            onChange={setEndDay}
          />
        </div>
      </div>
      <button
        type="button"
        id="menu"
        css={styles.menu}
        onClick={async () => {
          if (startDay === null || endDay === null) {
            setShowError(true);
          } else {
            setLoading(true);
            setShowError(false);
            await sendReportRequest(startDay.format(), endDay.format(), report, startDay);
            setTimeout(() => {
              setLoading(false);
            }, 1500);
          }
        }}
      ><div>{language.download}</div>
        <div css={css`height: 100%`}>
          <FaDownload
            css={styles.menuDark}
            size={18}
          />
        </div>
      </button>
      <div css={[styles.input, styles.error, showError ? {} : styles.hidden]}>
        {language.selectStartEndDate}
      </div>
    </div>
  );
};

const stylesFromTheme = (theme) => {
  return {
    background: css`
      margin: 4px;
      background-color: white;
      padding: 10px;
      border-radius: 4px;
      position: relative;
      height: fit-content;
      width: 100%;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        margin-left: 20px;
      }
    `,
    input: css`
      margin-top: 10px;
    `,
    hidden: css`
      display: none;
    `,
    error: css`
      background-color: rgba(94, 236, 125, 0.5);
      padding: 10px;
      border-radius: 5px;
    `,
    select: css`
      margin-left: 5px;
      width: 200px;
    `,
    calendar: css`
      max-width: 400px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        width: 100%;
      }
    `,
    menu: css`
      margin-left: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: ${theme.linkColors.reports};
      height: 35px;
      overflow: hidden;
      border-radius: 5px;
      padding-left: 20px;
      margin-top: 10px;
      font-weight: bold;
      :hover {
        box-shadow: 0 0 8px ${theme.shadow};
      }
      :active {
        color: black;
        box-shadow: 0 0 8px black;
      }
      :focus {
        outline: 0;
      }
    `,
    menuDark: css`
      height: 100%;
      background: ${theme.linkColors.darkAccent};
      padding: 10px;
      width: 35px;
      margin-left: 20px;
    `,
    dateRangePicker: css`
      height: 400px;
    `,
  };
};

export default ReportScreen;
