// Trend Screen v0.0.1
// Created By Trevor Colby
// IMPORTANT: This file should be identical for every mountain
// NOTE: CardStats need a rework
// any configuration should occur in './Configuration.js'
import React, {
  useState, useEffect, useContext,
} from 'react';
import { connect } from 'react-redux';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DateTimePicker } from '@material-ui/pickers';
import {
  CardChart, CardStat, ReactSelectAdapter, ColorPickerAdapter,
} from 'isno/lib/components/trends';
import { isEqual, startCase } from 'lodash';
import hkdLogo from 'isno/lib/static/images/isnoExportLogo.png';
import {
  FaPlus,
  FaTimes,
  FaDownload,
  FaSpinner,
  FaArrowCircleLeft,
  FaMinusCircle,
} from 'react-icons/fa';
import { Form, Field } from 'react-final-form';
import html2canvas from 'html2canvas';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import {
  addTrendWithDatasource,
  updateTrendWithDatasource,
  deleteTrend,
  updateTrend,
  fetchAllTrends,
  fetchLocations,
  fetchAllIOValues,
  fetchAllWeatherStations,
  // fetchTotalGallons,
  // fetchSnowmakingTrails,
  // fetchSnowmakingHours,
  // fetchAverageSei,
  fetchTrendDataset,
  fetchTrendDatasetDateRange,
} from '../actions';

const generateIOValueOptions = (ioValues) => {
  const temp = [];
  // Add available guns from guns api route
  if (ioValues) {
    Object.values(ioValues).forEach((ioValue) => {
      if (ioValue.isTrendable) {
        temp.push({
          value: `${ioValue.id}`,
          label: `${ioValue.name.replace(/_/gi, ' ')}`,
        });
      }
    });
  }
  return temp;
};

const generateLocationOptions = (locations) => {
  const tempLocationVals = {};
  const tempEquipmentVals = {};
  const tempIOVals = {};
  const tempReverseKey = {};
  // Add available guns from guns api route
  if (locations) {
    Object.values(locations).forEach((location) => {
      if (location.equipment) {
        const equip = Object.values(location.equipment);
        for (let i = 0; i < equip.length; i++) {
          if (equip[i].ioValues) {
            const ioVals = Object.values(equip[i].ioValues);
            if (ioVals) {
              for (let j = 0; j < ioVals.length; j++) {
                if (ioVals[j]?.isTrendable) {
                  // check if we need to initialize our nested objects
                  if (!tempLocationVals[location?.id]) {
                    tempLocationVals[location?.id] = { value: location?.id, label: location?.name };
                  }
                  if (!tempEquipmentVals[location?.id]) {
                    tempEquipmentVals[location?.id] = {};
                  }
                  if (!tempIOVals[location?.id]) {
                    tempIOVals[location?.id] = {};
                  }
                  if (!tempIOVals[location?.id][equip[i]?.id]) {
                    tempIOVals[location?.id][equip[i]?.id] = {};
                  }
                  // push equipment if not already in options
                  if (!tempEquipmentVals[location?.id]?.[equip[i]?.id]) {
                    tempEquipmentVals[location?.id][equip[i]?.id] = { value: equip[i]?.id, label: equip[i]?.name };
                  }
                  // push ioValue if not already in options
                  if (!tempIOVals[location?.id]?.[equip[i]?.id]?.[ioVals[j]?.id]) {
                    tempIOVals[location?.id][equip[i]?.id][ioVals[j]?.id] = { value: ioVals[j]?.id, label: ioVals[j]?.name };
                  }

                  // We need the reverse key to lookup location and equipment with an IO Value
                  tempReverseKey[ioVals[j]?.id] = {
                    name: ioVals[j]?.name,
                    location: { value: location?.id, label: location?.name },
                    equipment: { value: equip[i]?.id, label: equip[i]?.name },
                    units: ioVals[j]?.ioValueType?.units,
                  };
                }
              }
            }
          }
        }
      }
    });
  }
  return [tempLocationVals, tempEquipmentVals, tempIOVals, tempReverseKey];
};

const generateYAxes = (trendDatasourcesList) => {
  // set up default case for axes, i.e single axis
  const tempYAxes = {
    1: {
      type: 'linear',
      position: 'left',
      title: {
        display: true,
        text: 'Y1',
      },
    },
  };

  // if any datasets are on the secondary axis, add the secondary axis
  for (let i = 0; i < trendDatasourcesList.length; i++) {
    if (tempYAxes[2] === undefined && trendDatasourcesList[i].isSecondaryYaxis) {
      tempYAxes[2] = {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'Y2',
        },
      };
      return tempYAxes;
    }
  }
  return tempYAxes;
};

const generateChartJSDatasets = (datasets, trendDatasourcesList, weatherStationOptions, IOValueOptions, reverseKey) => {
  const chartDatasets = [];
  for (let i = 0; i < trendDatasourcesList.length; i++) {
    console.log(reverseKey[trendDatasourcesList[i].ioValue?.id]);
    // need to make a shallow copy of the array so that reverse() doesn't alter original data
    if (trendDatasourcesList[i].ioValueId) {
      if (datasets[trendDatasourcesList[i].ioValueId]) {
        const dataCopy = datasets[trendDatasourcesList[i].ioValueId].y.slice();
        chartDatasets.push(
          {
            yAxisID: trendDatasourcesList[i].isSecondaryYaxis ? '2' : '1',
            label: `${trendDatasourcesList[i].isSecondaryYaxis ? 'Y2' : 'Y1'} ${startCase(reverseKey[trendDatasourcesList[i].ioValue?.id]?.location?.label)} ${startCase(reverseKey[trendDatasourcesList[i].ioValue?.id]?.equipment?.label)} ${startCase(trendDatasourcesList[i].ioValue.name)|| 'No IO Value Selected'}${reverseKey[trendDatasourcesList[i].ioValue?.id]?.units ? ` (${reverseKey[trendDatasourcesList[i].ioValue?.id]?.units})` : ''}`,
            borderColor: trendDatasourcesList[i].color || 'grey',
            backgroundColor: trendDatasourcesList[i].color || 'grey',
            borderWidth: dataCopy.length > 70 ? 0.75 : 2.5,
            fill: false,
            pointRadius: dataCopy.length > 70 ? 1.5 : 3,
            pointHoverRadius: dataCopy.length > 70 ? 3 : 5,
            data: dataCopy.reverse(), // array of values
          },
        );
      }
    } else if (trendDatasourcesList[i].weatherId) {
      console.log(trendDatasourcesList[i].weatherMetric);
      if (datasets[`${trendDatasourcesList[i].weatherId}_${trendDatasourcesList[i].weatherMetric}`]) {
        let valueName = '';
        if (weatherStationOptions) {
          valueName = weatherStationOptions.find((weatherStation) => (weatherStation.value === `${trendDatasourcesList[i].weatherId}`))?.label;
        }
        const dataCopy = datasets[`${trendDatasourcesList[i].weatherId}_${trendDatasourcesList[i].weatherMetric}`].y.slice();
        chartDatasets.push(
          {
            yAxisID: trendDatasourcesList[i].isSecondaryYaxis ? '2' : '1',
            label: `${trendDatasourcesList[i].isSecondaryYaxis ? 'Y2' : 'Y1'} ${valueName} ${trendDatasourcesList[i].weatherMetric || 'No IO Value Selected'}`,
            borderColor: trendDatasourcesList[i].color || 'grey',
            backgroundColor: trendDatasourcesList[i].color || 'grey',
            borderWidth: dataCopy.length > 70 ? 0.75 : 2.5,
            fill: false,
            pointRadius: dataCopy.length > 70 ? 1.5 : 3,
            pointHoverRadius: dataCopy.length > 70 ? 3 : 5,
            data: dataCopy.reverse(), // array of values
          },
        );
      }
    }
  }
  return chartDatasets;
};

const generateTrendObject = (
  id,
  dates,
  datasets,
  title,
  gridPosition,
  trendDatasourcesList,
  yRefVal,
  yRefValColor,
  yRefVal2,
  yRefVal2Color,
  weatherStationOptions,
  IOValueOptions,
  reverseKey,
  activeFilter,
  quickFilter,
  language,
  onMobile,
) => {
  return {
    title,
    gridPosition,
    trendId: id,
    trendDatasourcesList,
    yRefVal,
    yRefValColor,
    yRefVal2,
    yRefVal2Color,
    chartJsData: {
      labels: dates, // array of dates, all the same for all trendIOValues -> need to make this standard across all
      datasets: generateChartJSDatasets(datasets, trendDatasourcesList, weatherStationOptions, IOValueOptions, reverseKey),
    },
    options: {
      plugins: {
        legend: {
          display: !onMobile,
        },
      },
      scales: {
        ...generateYAxes(trendDatasourcesList),
        x: {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 5,
            callback(value, index, values) {
              if (activeFilter === 1 && (quickFilter === language.quickFiltersOptions[5] || quickFilter === language.quickFiltersOptions[4])) {
                return moment.utc(`${this.getLabelForValue(value)}Z`).local().format('MM/DD');
              } else {
                return moment.utc(`${this.getLabelForValue(value)}Z`).local().format('kk:mm');
              }
            },
          },
        },
      },
      annotation: {
        annotations: {
          ...(yRefVal !== null ? {
            1: {
              drawTime: 'beforeDraw',
              type: 'line',
              scaleID: '1',
              value: yRefVal,
              borderColor: yRefValColor || 'grey',
              borderWidth: 2,
              label: {
                enabled: true,
                content: yRefVal, // Just label the reference with its value
              },
            },
          } : {}),
          ...(yRefVal2 !== null ? {
            2: {
              drawTime: 'beforeDraw',
              type: 'line',
              scaleID: '2',
              value: yRefVal2,
              borderColor: yRefVal2Color || 'grey',
              borderWidth: 2,
              label: {
                enabled: true,
                content: yRefVal2, // Just label the reference with its value
              },
            },
          } : {}),
        },
      },
    },
  };
};

// Allows conversion of dashboard into string
// This is what features we are watching for changes
function customReplacer(key, value) {
  // let data = value.chartJsData.datasets[0].data;
  let tempValue = value;
  if (value?.chartJsData) {
    // const colors = [];
    // const yaxis = [];
    const datasources = [];

    value.trendDatasourcesList.forEach((source) => {
      datasources.push(`${source?.id}_${source?.color}_${source?.isSecondaryYaxis}`);
    });

    const { trendId } = value;
    const { labels } = value.chartJsData;
    const { gridPosition } = value;
    const { title } = value;
    const { yRefVal } = value;
    const { yRefVal2 } = value;
    const { yRefVal2Color } = value;
    const { yRefValColor } = value;

    tempValue = {
      key,
      trendId,
      labels,
      title,
      yRefVal,
      yRefVal2,
      yRefValColor,
      yRefVal2Color,
      gridPosition,
      datasources,
    };
  }
  return tempValue;
}

// let weatherStationInitial, weatherValueInitial, axisInitial, colorInitial;
// const initialValues = {};
// Basic async wrapper for setTimeout
// allows sequential timer within async func
async function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
const TrendScreen = (props) => {
  const { language } = useContext(LanguageContext);
  const theme = useTheme();
  const styles = stylesFromTheme(theme);
  const { quickFiltersOptions } = language;

  const [onMobile, setOnMobile] = useState(false);
  const [quickFilter, setFilter] = useState(quickFiltersOptions[0]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeFilter, setActiveFilter] = useState(1); // 1-quickFilter || 2- hours / days dropdown || 3- datePicker
  const [isAddingChart, setIsAddingChart] = useState(false);
  const [isEditingChart, setIsEditingChart] = useState(false);
  const [loadingEditing, setLoadingEditing] = useState(false);
  const [chartToEdit, setChartToEdit] = useState([]);
  const [isTrashingChart, setIsTrashingChart] = useState(false);
  const [chartToDelete, setChartToDelete] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const [statsLoading, setStatsLoading] = useState(true);
  const [trendsLoading, setTrendsLoading] = useState(false);
  const [fetchingDatasets, setFetchingDatasets] = useState(true);
  const [loadingText, setLoadingText] = useState(`${language.contentLoading}...`);
  const [dashboard, setDashboard] = useState([]);
  const [sortedTrends, setSortedTrends] = useState([].concat(dashboard).sort((a, b) => { return (a.gridPosition > b.gridPosition) ? 1 : -1; }));
  const [IOValueOptions, setIOValueOptions] = useState(() => {
    return [];
  });
  const [weatherValueOptions, setWeatherValueOptions] = useState(() => {
    return [];
  });
  const [weatherStationOptions, setWeatherStationOptions] = useState(() => {
    return [];
  });
  const [locationOptions, setLocationOptions] = useState(() => {
    return [];
  });
  const [equipmentOptions, setEquipmentOptions] = useState(() => {
    return [];
  });
  const [ioOptions, setIOOptions] = useState(() => {
    return [];
  });
  const [reverseKey, setReverseKey] = useState(() => {
    return null;
  });
  const [IOValueIDs, setIOValueIDs] = useState(() => {
    return [];
  });
  const [weatherValueIDs, setWeatherValueIDs] = useState(() => {
    return [];
  });
  const [previousDatasets, setPreviousDatasets] = useState(() => {
    return {};
  });

  const [trendDataArray, setTrendDataArray] = useState({ 1: [0] });

  const [initialValues, setInitialValues] = useState({});

  const defaultDataType = weatherStationOptions.length > 0 ? { value: 0, label: language.weatherStation } : { value: 1, label: language.plantIOValue };
  const dataTypeOptions = [];
  if (weatherStationOptions.length > 0) {
    dataTypeOptions.push(
      {
        value: 0,
        label: language.weatherStation,
      },
    );
  }
  if (IOValueOptions.length > 0) {
    dataTypeOptions.push(
      {
        value: 1,
        label: language.plantIOValue,
      },
    );
  }

  const axisOptions = [
    {
      // value: 'primary', label: 'Primary',
      value: false, label: language.primary,
    },
    {
      // value: 'secondary', label: 'Secondary',
      value: true, label: language.secondary,
    },
  ];

  const colors = [
    '#808080',
    '#C4162A',
    '#3F7E2A',
    '#2959B7',
    '#FC6726',
    '#990199',
  ];

const tempWeatherValueOptions = [
  {
    value: 'PressureInHg',
    label: language.pressure,
  },
  {
    value: 'RelativeHumidity',
    label: language.relativeHumidity,
  },
  {
    value: 'TemperatureFahrenheit',
    label: language.tempFahrenheit,
  },
  {
    value: 'TemperatureCelsius',
    label: language.tempCelsius,
  },
  {
    value: 'WetbulbFahrenheit',
    label: language.wetbulbFahrenheit,
  },
  {
    value: 'WetbulbCelsius',
    label: language.wetbulbCelsius,
  },
  {
    value: 'WindDirectionDegrees',
    label: language.windDirection,
  },
  {
    value: 'WindSpeedMph',
    label: language.windSpeed,
  },
];

const generateWeatherStationOptions = (stations) => {
  const temp = [];
  // Add available guns from guns api route
  if (stations) {
    Object.values(stations).forEach((station) => {
      let label;
      if (station?.weatherlinkStation) {
        label = station?.weatherlinkStation?.stationName;
      } else if (station?.openWeatherMapLocation) {
        label = station?.openWeatherMapLocation?.name;
      } else if (station?.weatherStationEquipment) {
        label = station?.weatherStationEquipment?.name;
      } else {
        label = `${langauge.weatherStation} ${station?.id}`;
      }

      temp.push({
        value: `${station.id}`,
        label,
      });
    });
  }
  return temp;
};


  const printImage = () => {
    setIsPrinting(true);
  };

  const loadingStatsWrapper = async (timeString1, timeString2) => {
    setLoadingText(`${language.contentLoading}...`);
    setStatsLoading(true);
    await fetchStatsWrapper(timeString1, timeString2);
    setStatsLoading(false);
    setLoadingText('');
  };

  const fetchStatsWrapper = async (timeString1, timeString2) => {
    const promises = [
      // props.fetchTotalGallons(timeString1, [636], timeString2),
      // props.fetchTotalGallons(timeString1, [609], timeString2),
      // props.fetchTotalGallons(timeString1, [446], timeString2),
      // props.fetchTotalGallons(timeString1, [524], timeString2),
      // props.fetchTotalGallons(timeString1, [446], timeString2),
      // props.fetchTotalGallons(timeString1, [835], timeString2),
      // props.fetchTotalGallons(timeString1, [810], timeString2),
      // props.fetchSnowmakingHours(timeString1, timeString2),
      // props.fetchSnowmakingTrails(timeString1, timeString2),
      // props.fetchAverageSei(timeString1, [insert-hydrant-ids], timeString2),
    ];

    await Promise.all(promises);
  };
  
  // Returns true if screen is within our 'mobile' browser constraints
  const checkMobile = () => {
    const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    return width < theme.mobileBreakpoint;
  };

  useEffect(() => {
    setOnMobile(checkMobile());
  }, []);

  useEffect(() => {
    window.onresize = () => {
      setTimeout(() => {
        setOnMobile(checkMobile());
      }, 500);
    };
  }, []);

  useEffect(() => {
    if (isPrinting) {
      const input = document.getElementById('divToPrint');
      const date = new Date();
      html2canvas(input)
        .then((canvas) => {
          saveAs(canvas.toDataURL(), `iSno ${language.trends} - ${date}.png`);
        });
      setIsPrinting(false);
    }
  }, [isPrinting]);

  useEffect(() => {
    if (isAddingChart) {
      setTrendDataArray({ 1: [0] });
    }
  }, [isAddingChart]);

  useEffect(() => {
    // if (isEditingChart) {
    if (loadingEditing) {
      const temp = [];
      for (let i = 0; i < chartToEdit?.trendDatasourcesList.length; i++) {
        // temp.push(chartToEdit?.trendDatasourcesList[i].id);
        temp.push(i);
      }
      setTrendDataArray({ 1: temp });
    }
  }, [loadingEditing, chartToEdit]);

  useEffect(() => {
    // if (isEditingChart && trendDataArray && chartToEdit?.trendDatasourcesList) {
    if (loadingEditing && trendDataArray && chartToEdit?.trendDatasourcesList) {
      const temp = {};
      trendDataArray[1].forEach((item, index) => {
        temp[item] = {
          dataType: dataTypeOptions.find((datType) => (datType.value === (chartToEdit?.trendDatasourcesList[item]?.weatherId === null ? 1 : 0))),
          weatherStation: weatherStationOptions.find((weatherStation) => (weatherStation.value === `${chartToEdit?.trendDatasourcesList[item]?.weatherId}`)),
          weatherValue: weatherValueOptions.find((weatherValue) => (weatherValue.value === chartToEdit?.trendDatasourcesList[item]?.weatherMetric)),
          ioValue: IOValueOptions.find((ioValue) => (ioValue.value === `${chartToEdit?.trendDatasourcesList[item]?.ioValueId}`)),
          location: reverseKey[chartToEdit?.trendDatasourcesList[item]?.ioValueId]?.location,
          equipment: reverseKey[chartToEdit?.trendDatasourcesList[item]?.ioValueId]?.equipment,
          axis: {
            value: chartToEdit?.trendDatasourcesList[item]?.isSecondaryYaxis,
            label: chartToEdit?.trendDatasourcesList[item]?.isSecondaryYaxis ? language.secondary : language.primary,
          },
          color: { hex: chartToEdit?.trendDatasourcesList[item]?.color },
        };
      });
      setInitialValues(temp);
    }
  }, [loadingEditing, trendDataArray, chartToEdit]);

  useEffect(() => {
    if (loadingEditing && initialValues) {
      setIsEditingChart(true);
      setLoadingEditing(false);
    }
  }, [initialValues]);

  useEffect(() => {
    if (!fetchingDatasets && reverseKey) {
      // once all of our datasets have been fetched, update the dashboard
      updateDashboard();
    }
  }, [props.trends.datasets, props.trends.trends, fetchingDatasets, reverseKey, onMobile]);

  useEffect(() => {
    if (dashboard) {
      const tempSortedTrends = [].concat(dashboard).sort((a, b) => { return (a.gridPosition > b.gridPosition) ? 1 : -1; });
      // Here we stringify our dashboard to check if changes we cared about actually occured
      if (JSON.stringify(tempSortedTrends, customReplacer) !== JSON.stringify(sortedTrends, customReplacer)) {
        setSortedTrends(tempSortedTrends);
      }
    }
  }, [dashboard]);

  useEffect(() => {
    if (IOValueOptions.length < 1 && props.trends.IOValues == null) {
      props.fetchAllIOValues();
    }

    if (Object.values(props.trends.IOValues || {}).length > 0 && IOValueOptions.length < 1) {
      setIOValueOptions(generateIOValueOptions(props.trends.IOValues));
    }
  }, [props.trends.IOValues]);

  useEffect(() => {
    if (weatherValueOptions.length < 1 && props.weatherStations?.weatherStations == null) {
      props.fetchAllWeatherStations();
    }

    if (Object.values(props.weatherStations?.weatherStations || {}).length > 0 && weatherStationOptions.length < 1) {
      setWeatherValueOptions(tempWeatherValueOptions);
      setWeatherStationOptions(generateWeatherStationOptions(props.weatherStations?.weatherStations));
    }
  }, [props.weatherStations?.weatherStations]);

  useEffect(() => {
    if (props.locations?.locations) {
      const tuplet = generateLocationOptions(props.locations?.locations);
      setLocationOptions(Object.values(tuplet[0]));
      setEquipmentOptions(tuplet[1]);
      setIOOptions(tuplet[2]);
      setReverseKey(tuplet[3]);
    }
  }, [props.locations?.locations]);

  useEffect(() => {
    // - Gather the list of IOValues that we need,
    // - check for update everytime trends change or we get need trend data
    updateIOValueIDs(props.trends.trends);
    updateWeatherValueIDs(props.trends.trends);
  }, [props.trends.trends]);

  useEffect(() => {
    // Using our list of IOValueIDs gather all the datasets we need,
    // changes as IOValueIDs change, our desired timeperiod changes, or new trend data comes in
    updateDatasets(true);
  }, [IOValueIDs, weatherValueIDs, quickFilter, startDate, endDate]);

  useEffect(() => {
    updateDatasets();
  }, [props.trends.trends]);

  // May want to abstract to an updateStats function
  // May also want to abstract a timePeriod function that returns timeString1, timeString2
  useEffect(() => {
    if (activeFilter === 1) {
      if (IOValueOptions.length > 1) {
        let timePeriod = `${quickFilter.replace(/ /g, '').replace(/r/g, '').replace('Day', 'd').toLowerCase()}`;
        if (timePeriod.toLowerCase() === 'saison') {
          timePeriod = 'season';
        } else if (timePeriod.toLowerCase() === '7jous') {
          timePeriod = '7d';
        }
        loadingStatsWrapper(timePeriod);
      }
    } else if (activeFilter === 2) {
      if (IOValueOptions.length > 1 && startDate && endDate) {
        const startDateTemp = moment(startDate);
        const endDateTemp = moment(endDate);
        // let startDateString = startDate.utc().format()
        loadingStatsWrapper(startDateTemp.utc().format(), endDateTemp.utc().format());
      }
    }
    setStatsLoading(false);
  }, [activeFilter, quickFilter, startDate, endDate, IOValueOptions]);

  let oneMinuteIntervalId;
  const oneMinuteInterval = () => {
    oneMinuteIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchAllTrends(),
        props.fetchLocations(),
      ];
      await Promise.all(promises);
      oneMinuteIntervalId = setTimeout(oneMinuteInterval, 60000);
    }, 60000);
  };
  
  useEffect(() => {
    props.fetchAllTrends();
    props.fetchLocations();
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before starting our count to fetch data again. We use
    // this to make sure we aren't overloading our server.
    oneMinuteInterval();

    return () => {
      clearTimeout(oneMinuteIntervalId);
    };
  }, []);

  const saveAs = (uri, filename) => {
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = uri;
      link.download = filename;
      // Firefox requires the link to be in the body
      document.body.appendChild(link);

      // simulate click
      link.click();
      // remove the link when done
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  };

  const downloadCSV = () => {
    let csvContent = 'data:text/csv;charset=utf-8,';

    let headerRow = 'Date,';
    headerRow += Object.values(props.trends.datasets).map((c) => {
      if (c.ioValueId) {
        // return `IO ID: ${c.ioValueId}`;
        return `${reverseKey[c.ioValueId]?.location?.label} ${reverseKey[c.ioValueId]?.equipment?.label} ${reverseKey[c.ioValueId].name.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }) || 'No IO Value Selected'}`;
      } else if (c.weatherId) {
        let name = '';
        if (props.weatherStations?.weatherStations?.[c.weatherId]?.openWeatherMapLocation) {
          name = props.weatherStations?.weatherStations?.[c.weatherId]?.openWeatherMapLocation?.name;
        } else if (props.weatherStations?.weatherStations?.[c.weatherId]?.weatherlinkStation) {
          name = props.weatherStations?.weatherStations?.[c.weatherId]?.weatherlinkStation?.stationName;
        } else if (props.weatherStations?.weatherStations?.[c.weatherId]?.weatherStationEquipment) {
          name = props.weatherStations?.weatherStations?.[c.weatherId]?.weatherStationEquipment?.name;
        }
        return `${name}: ${c.weatherMetric}`;
      } else {
        return '';
      }
    }).join(',');
    headerRow = headerRow.replace(',,,', '');
    csvContent += `${headerRow}\r\n`;

    const dateRow = [];
    const dataCopyX = Object.values(props.trends.datasets)[0].x.slice();
    // need to make copy so reverse doesn't affect original
    dataCopyX.reverse().forEach((value) => {
      dateRow.push(moment(value).local().format('YYYY-MM-DD kk:mm'));
    });

    const valueArrays = Object.values(props.trends.datasets).map((r) => {
      const row = [];
      // need to make copy so reverse doesn't affect original
      const dataCopyY = r.y.slice();
      dataCopyY.reverse().forEach((value) => {
        row.push(value);
      });
      return row;
    });

    // here c is our column, and r is our value to be placed into that column
    for (let v = 0; v < dateRow.length; v++) {
      for (let c = 0; c <= valueArrays.length; c++) {
        if (c === 0) {
          csvContent += `${dateRow[v]},`;
        } else if (valueArrays[c - 1][v] !== undefined) {
          csvContent += `${valueArrays[c - 1][v]},`;
        } else {
          csvContent += 'null,';
        }
        if (c === valueArrays.length) {
          csvContent += '\r\n';
        }
      }
    }

    const encodedUri = encodeURI(csvContent);

    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      // Below allows us to auto initiate the download of our file
      const filename = `iSno-${language.trends}-${new Date().toLocaleDateString()}.csv`;
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(encodedUri);
    }
  };

  // const renderAddChartData = (styles, weatherStationOptions, axisOptions, ioSelectStyles, IOValueOptions, weatherValueOptions, colors, language, trendDataArray, setTrendDataArray, values, locationOptions, equipmentOptions, ioOptions, form) => {
  const renderAddChartData = (values, form) => {
    return (
      <div css={css`width: fit-content; max-width: 100%; display: flex; flex-direction: column;`}>
        {trendDataArray[1].map((item, index) => {
          return (
            <div key={`${item}`} css={styles.formDataBackground}>
              <FaTimes
                css={[styles.iconTimes, trendDataArray[1].length <= 1 ? styles.actionBlocked : {}]}
                onClick={() => {
                  if (trendDataArray[1].length > 1) {
                    const tempArray = trendDataArray[1];
                    tempArray.splice(index, 1);
                    setTrendDataArray({ 1: tempArray });
                  }
                }}
                size={28}
              />
              <div css={styles.singleInput}>
                <div css={css`margin-right: 10px;`}>{`${language.dataType}:`}</div>
                <div css={styles.formDataContainer}>
                  <div
                    id={`dataType${item}`}
                    css={css`margin: 5px 14px 0px 0px;`}
                  >
                    <Field
                      name={`dataType${item}`}
                      component={ReactSelectAdapter}
                      initialValue={defaultDataType}
                      options={dataTypeOptions}
                      styles={ioSelectStyles}
                      placeholder={`${language.dataType}...`}
                      customOnChange={(e) => {
                      // if (values?.[`dataType${item}`]?.value === 1) {
                        form.change(`dataSource${item}`, undefined);
                        form.change(`equipment${item}`, undefined);
                        form.change(`dataValue${item}`, undefined);
                      // }
                      }}
                    />
                  </div>
                  <div css={css`min-width: 238px; margin-top: 5px;`} />
                </div>
              </div>
              {/* {values?.[`dataType${item}`]?.value === 0
          && ( */}
              <div css={styles.singleInput}>
                <div css={css`margin-right: 10px;`}>{`${language.dataSource}:`}</div>
                <div css={styles.formDataContainer}>
                  <div
                    id={`dataSource${item}`}
                    css={css`margin: 5px 14px 0px 0px;`}
                  >
                    <Field
                      name={`dataSource${item}`}
                      component={ReactSelectAdapter}
                      options={values?.[`dataType${item}`]?.value === 0 ? weatherStationOptions : locationOptions}
                      styles={ioSelectStyles}
                      placeholder={values?.[`dataType${item}`]?.value === 0 ? `${language.weatherStation}...` : `${language.location}...`}
                      customOnChange={(e) => {
                      // if (values?.[`dataType${item}`]?.value === 1) {
                        form.change(`equipment${item}`, undefined);
                        form.change(`dataValue${item}`, undefined);
                      // }
                      }}
                    />
                  </div>
                  <div css={css`min-width: 238px; margin-top: 5px;`} />
                </div>
              </div>
              {/* )} */}
              {values?.[`dataType${item}`]?.value === 1
          && (
          <div css={styles.singleInput}>
            <div css={css`margin-right: 10px;`}>{`${language.equipment}:`}</div>
            <div css={styles.formDataContainer}>
              <div
                id={`equipment${item}`}
                css={css`margin: 5px 14px 0px 0px;`}
              >
                <Field
                  name={`equipment${item}`}
                  component={ReactSelectAdapter}
                  options={
                    values?.[`dataSource${item}`]?.value !== undefined
                    && equipmentOptions[values?.[`dataSource${item}`]?.value] !== undefined
                      ? Object.values(equipmentOptions[values?.[`dataSource${item}`]?.value]) : undefined
                  }
                  customOnChange={(e) => {
                    form.change(`dataValue${item}`, undefined);
                  }}
                  styles={ioSelectStyles}
                  placeholder={`${language.equipment}...`}
                />
              </div>
              <div css={css`min-width: 238px; margin-top: 5px;`} />
            </div>
          </div>
          )}
              {values?.[`dataType${item}`]?.value === 1
          && (
            <div css={[styles.singleInput]}>
              <div css={css`margin-right: 10px;`}>{`${language.dataValue}:`}</div>
              <div css={styles.formDataContainer}>
                <div
                  id={`dataValue${item}`}
                  css={css`margin: 5px 14px 0px 0px;`}
                >
                  <Field
                    name={`dataValue${item}`}
                    component={ReactSelectAdapter}
                    options={
                      values?.[`dataSource${item}`]?.value !== undefined
                      && values?.[`equipment${item}`]?.value !== undefined
                      && ioOptions?.[values?.[`dataSource${item}`]?.value] !== undefined
                      && ioOptions?.[values?.[`dataSource${item}`]?.value]?.[values?.[`equipment${item}`]?.value] !== undefined
                        ? Object.values(ioOptions?.[values?.[`dataSource${item}`]?.value]?.[values?.[`equipment${item}`]?.value]) : undefined
                    }
                    styles={ioSelectStyles}
                    placeholder={values?.[`dataType${item}`]?.value === 0 ? `${language.weatherValue}...` : `${language.selectIO}...`}
                  />
                </div>
                <div css={css`min-width: 238px; margin-top: 5px;`}>
                  <Field
                    name={`dataColor${item}`}
                    component={ColorPickerAdapter}
                    initialValue="#808080"
                    colors={colors}
                    width="fit-content"
                  />
                </div>
              </div>
            </div>
          )}
              {values?.[`dataType${item}`]?.value === 0
          && (
            <div css={[styles.singleInput]}>
              <div css={css`margin-right: 10px;`}>{`${language.dataValue}:`}</div>
              <div css={styles.formDataContainer}>
                <div
                  id={`dataValue${item}`}
                  css={css`margin: 5px 14px 0px 0px;`}
                >
                  <Field
                    name={`dataValue${item}`}
                    component={ReactSelectAdapter}
                    options={weatherValueOptions}
                    styles={ioSelectStyles}
                    placeholder="Weather Value..."
                  />
                </div>
                <div css={css`min-width: 238px; margin-top: 5px;`}>
                  <Field
                    name={`dataColor${item}`}
                    component={ColorPickerAdapter}
                    initialValue="#808080"
                    colors={colors}
                    width="fit-content"
                  />
                </div>
              </div>
            </div>
          )}
              <div css={styles.singleInput}>
                <div css={css`margin-right: 10px;`}>{`${language.axis}:`}</div>
                <div css={styles.formDataContainer}>
                  <div
                    id={`axisControl${item}`}
                    css={css`margin: 5px 14px 0px 0px;`}
                  >
                    <Field
                      name={`axisControl${item}`}
                      component={ReactSelectAdapter}
                      options={axisOptions}
                      styles={ioSelectStyles}
                      placeholder={`${language.selectAxis}...`}
                    />
                  </div>
                  <div css={css`min-width: 238px; margin-top: 5px;`} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderEditChartData = (values, form) => {
    return (
      <div css={css`width: fit-content; max-width: 100%; display: flex; flex-direction: column;`}>
        {trendDataArray[1].map((item, index) => {
          // weatherStationInitial = weatherStationOptions.find((weatherStation) => (weatherStation.value === `${chartToEdit?.trendDatasourcesList[item]?.weatherId}`));
          // weatherValueInitial = weatherValueOptions.find((weatherValue) => (weatherValue.value === chartToEdit?.trendDatasourcesList[item].weatherMetric));
          // axisInitial = {
          //   value: chartToEdit?.trendDatasourcesList[item]?.isSecondaryYaxis,
          //   label: chartToEdit?.trendDatasourcesList[item]?.isSecondaryYaxis ? 'Secondary' : 'Primary',
          // };
          // colorInitial = { hex: chartToEdit?.trendDatasourcesList[item]?.color };
          return (
            <div key={`${item}`} css={styles.formDataBackground}>
              <FaTimes
                css={[styles.iconTimes, trendDataArray[1].length <= 1 ? styles.actionBlocked : {}]}
                onClick={() => {
                  if (trendDataArray[1].length > 1) {
                    const tempArray = trendDataArray[1];
                    tempArray.splice(index, 1);
                    setTrendDataArray({ 1: tempArray });
                  }
                }}
                size={28}
              />
              <div css={styles.singleInput}>
                <div css={css`margin-right: 10px;`}>{`${language.dataType}:`}</div>
                <div css={styles.formDataContainer}>
                  <div css={css`margin: 5px 14px 0px 0px;`}>
                    <Field
                      name={`dataType${item}`}
                      component={ReactSelectAdapter}
                      initialValue={initialValues?.[item]?.dataType ? initialValues?.[item]?.dataType : defaultDataType}
                      options={dataTypeOptions}
                      styles={ioSelectStyles}
                      placeholder={`${language.dataType}...`}
                      customOnChange={(e) => {
                      // if (values?.[`dataType${item}`]?.value === 1) {
                        form.change(`dataSource${item}`, undefined);
                        form.change(`equipment${item}`, undefined);
                        form.change(`dataValue${item}`, undefined);
                      // }
                      }}
                    />
                  </div>
                  <div css={css`min-width: 238px; margin-top: 5px;`} />
                </div>
              </div>
              <div css={styles.singleInput}>
                <div css={css`margin-right: 10px;`}>{`${language.dataSource}:`}</div>
                <div css={styles.formDataContainer}>
                  <div
                    id={`dataSource${item}`}
                    css={css`margin: 5px 14px 0px 0px;`}
                  >
                    {values?.[`dataType${item}`]?.value === 1
                && (
                <div>
                  {initialValues?.[item]?.location
                    ? (
                      <Field
                        name={`dataSource${item}`}
                        component={ReactSelectAdapter}
                        options={locationOptions}
                        styles={ioSelectStyles}
                        placeholder={`${language.location}...`}
                        initialValue={initialValues?.[item]?.location}
                        customOnChange={(e) => {
                        // if (values?.[`dataType${item}`]?.value === 1) {
                          form.change(`equipment${item}`, undefined);
                          form.change(`dataValue${item}`, undefined);
                        // }
                        }}
                      />
                  ) : (
                    <Field
                      name={`dataSource${item}`}
                      component={ReactSelectAdapter}
                      options={locationOptions}
                      styles={ioSelectStyles}
                      placeholder={`${language.location}...`}
                      customOnChange={(e) => {
                      // if (values?.[`dataType${item}`]?.value === 1) {
                        form.change(`equipment${item}`, undefined);
                        form.change(`dataValue${item}`, undefined);
                      // }
                      }}
                    />
                  )}
                </div>
                )}
                    {values?.[`dataType${item}`]?.value === 0
                && (
                <div>
                  {initialValues?.[item]?.weatherStation !== undefined
                    ? (
                      <Field
                        name={`dataSource${item}`}
                        component={ReactSelectAdapter}
                        options={weatherStationOptions}
                        styles={ioSelectStyles}
                        initialValue={initialValues?.[item]?.weatherStation}
                        placeholder={`${language.weatherStation}...`}
                      />
                    ) : (
                      <Field
                        name={`dataSource${item}`}
                        component={ReactSelectAdapter}
                        options={weatherStationOptions}
                        styles={ioSelectStyles}
                        placeholder={`${language.weatherStation}...`}
                      />
                )}
                </div>
                )}
                  </div>
                  <div css={css`min-width: 238px; margin-top: 5px;`} />
                </div>
              </div>
              {values?.[`dataType${item}`]?.value === 1
              && (
              <div css={styles.singleInput}>
                <div css={css`margin-right: 10px;`}>{`${language.equipment}:`}</div>
                <div css={styles.formDataContainer}>
                  <div
                    id={`equipment${item}`}
                    css={css`margin: 5px 14px 0px 0px;`}
                  >
                    {initialValues?.[item]?.equipment
                      ? (
                        <Field
                          name={`equipment${item}`}
                          component={ReactSelectAdapter}
                          initialValue={initialValues?.[item]?.equipment}
                          options={
                            values?.[`dataSource${item}`]?.value !== undefined
                            && equipmentOptions[values?.[`dataSource${item}`]?.value] !== undefined
                              ? Object.values(equipmentOptions[values?.[`dataSource${item}`]?.value]) : undefined
                          }
                          customOnChange={(e) => {
                            form.change(`dataValue${item}`, undefined);
                          }}
                          styles={ioSelectStyles}
                          placeholder={`${language.equipment}...`}
                        />
                    ) : (
                      <Field
                        name={`equipment${item}`}
                        component={ReactSelectAdapter}
                        options={
                          values?.[`dataSource${item}`]?.value !== undefined
                          && equipmentOptions[values?.[`dataSource${item}`]?.value] !== undefined
                            ? Object.values(equipmentOptions[values?.[`dataSource${item}`]?.value]) : undefined
                        }
                        customOnChange={(e) => {
                          form.change(`dataValue${item}`, undefined);
                        }}
                        styles={ioSelectStyles}
                        placeholder={`${language.equipment}...`}
                      />
                    )}
                  </div>
                  <div css={css`min-width: 238px; margin-top: 5px;`} />
                </div>
              </div>
              )}
              <div css={[styles.singleInput]}>
                <div css={css`margin-right: 10px;`}>{`${language.dataValue}:`}</div>
                <div css={styles.formDataContainer}>
                  <div
                    id={`dataValue${item}`}
                    css={css`margin: 5px 14px 0px 0px;`}
                  >
                    {values?.[`dataType${item}`]?.value === 1 && initialValues?.[item]?.ioValue !== undefined
                    && (
                      <Field
                        name={`dataValue${item}`}
                        component={ReactSelectAdapter}
                        initialValue={initialValues?.[item]?.ioValue}
                        options={
                          values?.[`dataSource${item}`]?.value !== undefined
                          && values?.[`equipment${item}`]?.value !== undefined
                          && ioOptions?.[values?.[`dataSource${item}`]?.value] !== undefined
                          && ioOptions?.[values?.[`dataSource${item}`]?.value]?.[values?.[`equipment${item}`]?.value] !== undefined
                            ? Object.values(ioOptions?.[values?.[`dataSource${item}`]?.value]?.[values?.[`equipment${item}`]?.value]) : undefined
                        }
                        styles={ioSelectStyles}
                        placeholder={`${language.selectIO}...`}
                      />
                    )}
                    {values?.[`dataType${item}`]?.value === 1 && initialValues?.[item]?.ioValue === undefined
                    && (
                      <Field
                        name={`dataValue${item}`}
                        component={ReactSelectAdapter}
                        options={
                          values?.[`dataSource${item}`]?.value !== undefined
                          && values?.[`equipment${item}`]?.value !== undefined
                          && ioOptions?.[values?.[`dataSource${item}`]?.value] !== undefined
                          && ioOptions?.[values?.[`dataSource${item}`]?.value]?.[values?.[`equipment${item}`]?.value] !== undefined
                            ? Object.values(ioOptions?.[values?.[`dataSource${item}`]?.value]?.[values?.[`equipment${item}`]?.value]) : undefined
                        }
                        styles={ioSelectStyles}
                        placeholder={`${language.selectIO}...`}
                      />
                    )}
                    {values?.[`dataType${item}`]?.value === 0 && initialValues?.[item]?.weatherValue !== undefined
                    && (
                      <Field
                        name={`dataValue${item}`}
                        component={ReactSelectAdapter}
                        options={weatherValueOptions}
                        styles={ioSelectStyles}
                        // initialValue={weatherValueInitial}
                        initialValue={initialValues?.[item]?.weatherValue}
                        placeholder={`${language.weatherValue}...`}
                      />
                    )}
                    {values?.[`dataType${item}`]?.value === 0 && initialValues?.[item]?.weatherValue === undefined
                    && (
                      <Field
                        name={`dataValue${item}`}
                        component={ReactSelectAdapter}
                        options={weatherValueOptions}
                        styles={ioSelectStyles}
                        placeholder={`${language.weatherValue}...`}
                      />
                    )}
                  </div>
                  <div css={css`min-width: 238px; margin-top: 5px;`}>
                    {initialValues?.[item]?.color !== undefined
                      ? (
                        <Field
                          name={`dataColor${item}`}
                          component={ColorPickerAdapter}
                          colors={colors}
                          initialValue={initialValues?.[item]?.color}
                          width="fit-content"
                        />
                    ) : (
                      <Field
                        name={`dataColor${item}`}
                        component={ColorPickerAdapter}
                        initialValue="#808080"
                        colors={colors}
                        width="fit-content"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div css={styles.singleInput}>
                <div css={css`margin-right: 10px;`}>{`${language.axis}:`}</div>
                <div css={styles.formDataContainer}>
                  <div
                    id={`axisControl${item}`}
                    css={css`margin: 5px 14px 0px 0px;`}
                  >
                    {initialValues?.[item]?.axis !== undefined
                      ? (
                        <Field
                          name={`axisControl${item}`}
                          component={ReactSelectAdapter}
                          options={axisOptions}
                          styles={ioSelectStyles}
                          initialValue={initialValues?.[item]?.axis}
                          placeholder={`${language.selectAxis}...`}
                        />
                    ) : (
                      <Field
                        name={`axisControl${item}`}
                        component={ReactSelectAdapter}
                        options={axisOptions}
                        styles={ioSelectStyles}
                        placeholder={`${language.selectAxis}...`}
                      />
                    )}
                  </div>
                  <div css={css`min-width: 238px; margin-top: 5px;`} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const updateIOValueIDs = (trends) => {
    if (IOValueOptions.length > 1) {
      if (trends) {
        let tempIOValueIDs = [];
        // Iterate through our trends and collect all of the IOValueIDs that
        // we need to get datasets for
        const trendsArray = Object.values(trends);
        for (let i = 0; i < trendsArray.length; i++) {
          if (trendsArray[i].trendDatasourcesList) {
            if (trendsArray[i].trendDatasourcesList.length > 0) {
              for (let j = 0; j < trendsArray[i].trendDatasourcesList.length; j++) {
              // If we don't have that IOValue yet, add it to our list of IOValues
                // if (trendsArray[i].trendDatasourcesList[j].ioValueId && tempIOValueIDs.indexOf(trendsArray[i].trendDatasourcesList[j].ioValueId) < 0) {
                if (tempIOValueIDs.indexOf(trendsArray[i].trendDatasourcesList[j].ioValueId) < 0) {
                  // tempIOValueIDs.push(trendsArray[i].trendDatasourcesList[j].ioValueId);
                  tempIOValueIDs.push({ ioValueId: trendsArray[i].trendDatasourcesList[j]?.id });
                }
              }
            }
          }
        }
        // sorted so we can check for changes
        // tempIOValueIDs = tempIOValueIDs.sort();
        tempIOValueIDs = tempIOValueIDs.sort((a, b) => { return (a.ioValueId > b.ioValueId) ? 1 : -1; });
        // Only updated if we detect a change in the list of IOValues
        if (JSON.stringify(tempIOValueIDs) !== JSON.stringify(IOValueIDs)) {
          setIOValueIDs(tempIOValueIDs);
        }
      }
    }
  };

  const updateWeatherValueIDs = (trends) => {
    if (weatherValueOptions.length > 1) {
      if (trends) {
        let tempWeatherValueIDs = [];
        // Iterate through our trends and collect all of the IOValueIDs that
        // we need to get datasets for
        const trendsArray = Object.values(trends);
        for (let i = 0; i < trendsArray.length; i++) {
          if (trendsArray[i].trendDatasourcesList) {
            if (trendsArray[i].trendDatasourcesList.length > 0) {
              for (let j = 0; j < trendsArray[i].trendDatasourcesList.length; j++) {
              // If we don't have that IOValue yet, add it to our list of IOValues
                if (tempWeatherValueIDs.indexOf(trendsArray[i].trendDatasourcesList[j].weatherId) < 0) {
                  tempWeatherValueIDs.push({ weatherId: trendsArray[i].trendDatasourcesList[j]?.id, weatherMetric: trendsArray[i].trendDatasourcesList[j]?.weatherMetric });
                }
              }
            }
          }
        }
        // sorted so we can check for changes
        tempWeatherValueIDs = tempWeatherValueIDs.sort((a, b) => { return (a.weatherMetric > b.weatherMetric) ? 1 : -1; });
        // Only updated if we detect a change in the list of IOValues
        if (JSON.stringify(tempWeatherValueIDs) !== JSON.stringify(weatherValueIDs)) {
          setWeatherValueIDs(tempWeatherValueIDs);
        }
      }
    }
  };

  const updateDatasets = async (loading) => {
    if (IOValueIDs.length > 0 || weatherValueIDs.length > 0) {
      if (activeFilter === 1) {
        // set
        let timePeriod = `${quickFilter.replace(/ /g, '').replace(/r/g, '').replace('Day', 'd').toLowerCase()}`;
        if (timePeriod.toLowerCase() === 'saison') {
          timePeriod = 'season';
        } else if (timePeriod.toLowerCase() === '7jous') {
          timePeriod = '7d';
        }
        setFetchingDatasets(true);
        if (loading) {
          // don't always want to have the loading screen (i.e during interval dataset updates)
          setTrendsLoading(true);
        }
        let promises;
        if (IOValueIDs.length > 0) {
          promises = IOValueIDs.map(async (ioValueId) => {
            return props.fetchTrendDataset(timePeriod, ioValueId?.ioValueId);
          });
        }
        if (weatherValueIDs.length > 0) {
          promises = weatherValueIDs.map(async (weatherValueID) => {
            return props.fetchTrendDataset(timePeriod, weatherValueID.weatherId, weatherValueID.weatherMetric);
          });
        }
        await Promise.all(promises);
        setTrendsLoading(false);
        setFetchingDatasets(false);
      } else if (activeFilter === 2) {
        if (startDate && endDate) {
          setFetchingDatasets(true);
          if (loading) {
            // don't always want to have the loading screen (i.e during interval dataset updates)
            setTrendsLoading(true);
          }
          let promises;
          const startDateTemp = moment(startDate);
          const endDateTemp = moment(endDate);
          if (IOValueIDs.length > 0) {
            promises = IOValueIDs.map(async (ioValueId) => {
              return props.fetchTrendDatasetDateRange(startDateTemp.utc().format('YYYY-MM-DDTHH:mm:ss'), ioValueId?.ioValueId, endDateTemp.utc().format('YYYY-MM-DDTHH:mm:ss'));
            });
          }
          if (weatherValueIDs.length > 0) {
            promises = weatherValueIDs.map(async (weatherValueID) => {
              return props.fetchTrendDatasetDateRange(startDateTemp.utc().format('YYYY-MM-DDTHH:mm:ss'), weatherValueID.weatherId, endDateTemp.utc().format('YYYY-MM-DDTHH:mm:ss'), weatherValueID.weatherMetric);
            });
          }
          await Promise.all(promises);
          setTrendsLoading(false);
          setFetchingDatasets(false);
        }
      }
    }
  };

  const checkAllIOValues = (trendDatasourcesList, datasets) => {
    for (let i = 0; i < trendDatasourcesList.length; i++) {
      // check all of our IOValues to make sure we have a dataset for each one
      if (trendDatasourcesList[i].ioValueId) {
        if (!datasets[trendDatasourcesList[i].ioValueId]) {
          return false;
        }
      } else if (trendDatasourcesList[i].weatherId) {
        if (!datasets[`${trendDatasourcesList[i].weatherId}_${trendDatasourcesList[i].weatherMetric}`]) {
          return false;
        }
      }
    }
    return true;
  };

  const updateDashboard = async () => {
    const tempDash = [];
    if (props.trends.datasets && Object.values(props.trends.datasets).length > 0) {
      const trendsArray = Object.values(props.trends.trends);
      // all of the y-axis dates will be the same so we should only generate these once
      // because the iteration is costly for large datasets
      // const dates = Object.values(props.trends.datasets)[0].x.map((value) => (moment(value).format('MM/DD (kk:mm)'))).reverse();
      // return (`${new Date(`${row[key]}Z`).toLocaleString('en-US', { timeZoneName: 'short' })}`);
      const stringDatasets = JSON.stringify(props.trends.datasets);
      if (stringDatasets !== previousDatasets) {
        setPreviousDatasets(stringDatasets);
        // Insert just the plain text date, need to reverse to get the dates in chronological order
        // we do custom formatting for the xAxes and hover label later with callbacks
        const dates = Object.values(props.trends.datasets)[0].x.sort((a, b) => (new Date(a) - new Date(b)));

        for (let i = 0; i < trendsArray.length; i++) {
          if (trendsArray[i]?.trendDatasourcesList?.length > 0) {
            if (checkAllIOValues(trendsArray[i]?.trendDatasourcesList, props.trends.datasets)) {
              tempDash.push(
                generateTrendObject(
                  trendsArray[i].id,
                  dates,
                  props.trends.datasets,
                trendsArray[i]?.title,
                trendsArray[i]?.gridPosition,
                trendsArray[i]?.trendDatasourcesList,
                trendsArray[i]?.referenceValueY1,
                trendsArray[i]?.referenceColorY1,
                trendsArray[i]?.referenceValueY2,
                trendsArray[i]?.referenceColorY2,
                weatherStationOptions,
                IOValueOptions,
                reverseKey,
                activeFilter,
                quickFilter,
                language,
                onMobile,
                ),
              );
            }
          }
        }
        setDashboard(tempDash);
      }
    }
  };

  const onSubmit = async (values) => {
    const newTrend = {
      Title: values.title,
      GridPosition: values.gridPosition,
      referenceValueY1: values.referenceValueY1 ? `${values.referenceValueY1}` : null,
      referenceColorY1: values.referenceColorY1?.hex ? `${values.referenceColorY1.hex}` : null,
      referenceValueY2: values.referenceValueY2 ? `${values.referenceValueY2}` : null,
      referenceColorY2: values.referenceColorY2?.hex ? `${values.referenceColorY2?.hex}` : null,
    };

    const newTrendIOValues = [];
    // This is silly, need to make it trendDataArray instead of trendDataArray[1]
    if (trendDataArray[1]) {
      trendDataArray[1].forEach((item, index) => {
        if (values?.[`dataType${item}`]?.value === 0) {
          newTrendIOValues.push({
            weatherId: values?.[`dataSource${item}`]?.value,
            weatherMetric: values?.[`dataValue${item}`]?.value,
            Color: values?.[`dataColor${item}`]?.hex,
            isSecondaryYaxis: values?.[`axisControl${item}`]?.value,
          });
        } else {
          newTrendIOValues.push({
            IOValueId: values?.[`dataValue${item}`]?.value,
            Color: values?.[`dataColor${item}`]?.hex,
            isSecondaryYaxis: values?.[`axisControl${item}`]?.value,
          });
        }
      });
    }
    setLoadingText(`${language.addingTrend}...`);
    setTrendsLoading(true);
    setIsAddingChart(false);
    await props.addTrendWithDatasource(newTrend, newTrendIOValues);
    await wait(2000);
    props.fetchAllTrends();
    await wait(2000);
    setTrendsLoading(false);
    setLoadingText('');
  };

  const onEditSubmit = async (values) => {
    const updatedTrend = {
      Title: values.title,
      GridPosition: values.gridPosition,
      referenceValueY1: values.referenceValueY1 ? `${values.referenceValueY1}` : null,
      referenceColorY1: values.referenceColorY1?.hex ? `${values.referenceColorY1.hex}` : null,
      referenceValueY2: values.referenceValueY2 ? `${values.referenceValueY2}` : null,
      referenceColorY2: values.referenceColorY2?.hex ? `${values.referenceColorY2?.hex}` : null,
    };

    const updatedTrendIOValues = [];
    if (trendDataArray[1]) {
      trendDataArray[1].forEach((item, index) => {
        if (values?.[`dataType${item}`]?.value === 0) {
          updatedTrendIOValues.push({
            weatherId: values?.[`dataSource${item}`]?.value,
            weatherMetric: values?.[`dataValue${item}`]?.value,
            Color: values?.[`dataColor${item}`]?.hex,
            isSecondaryYaxis: values?.[`axisControl${item}`]?.value,
          });
        } else {
          updatedTrendIOValues.push({
            IOValueId: values?.[`dataValue${item}`]?.value,
            Color: values?.[`dataColor${item}`]?.hex,
            isSecondaryYaxis: values?.[`axisControl${item}`]?.value,
          });
        }
      });
    }

    setLoadingText(`${language.updatingTrend}...`);
    setTrendsLoading(true);
    setIsEditingChart(false);
    // await props.updateTrend(updatedTrend, updatedTrendIOValues);
    // Update the basic trend parts
    await props.updateTrend(chartToEdit.trendId, updatedTrend);
    // Update the trend datasources
    await props.updateTrendWithDatasource(chartToEdit.trendId, chartToEdit?.trendDatasourcesList, updatedTrendIOValues);
    await wait(2000);
    props.fetchAllTrends();
    await wait(2000);
    setTrendsLoading(false);
    setLoadingText('');
  };

  const handleDelete = async () => {
    setLoadingText(`${language.deletingTrend}...`);
    setTrendsLoading(true);
    setIsTrashingChart(!isTrashingChart);
    await props.deleteTrend(chartToDelete.trendId);
    await wait(2000);
    setTrendsLoading(false);
    setLoadingText('');
  };

  // Export these style customization to iSno
  // For further micro customization see:
  // https://react-select.com/components
  const ioSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      background: state.isDisabled ? 'transparent' : '#fff',
      borderColor: '#9e9e9e',
      minHeight: '28px',
      maxHeight: '28px',
      height: '28px',
      width: '177px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '24px',
      // width: '157px',
      padding: '0 6px',
      cursor: 'pointer',
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: (state) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '24px',
      width: '20px',
      padding: '0',
      cursor: 'pointer',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      width: '20px',
      padding: '10px 0',
      color: state.isDisabled ? 'transparent' : '#9e9e9e',
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'hsl(0,0%,20%)',
      fontSize: '14px',
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 999,
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 'fit-content',
      minWidth: '177px',
    }),
  };

  const errorBorder = '2px solid red';
  const validBorder = '2px solid transparent';
  const hasTrendDataValues = (values) => {
    let hasAllValues = true;
    // Highlight missing data
    const title = document.getElementById('title');
    if (title) {
      if (!values.title) {
        hasAllValues = false;
        title.style.border = errorBorder;
      } else {
        title.style.border = validBorder;
      }
    }
    const gridPosition = document.getElementById('gridPosition');
    if (gridPosition) {
      if (values.gridPosition === undefined) {
        gridPosition.style.border = errorBorder;
        hasAllValues = false;
      } else {
        gridPosition.style.border = validBorder;
      }
    }
    trendDataArray[1].forEach((item, index) => {
      if (
        (
          (values?.[`dataType${item}`]?.value === 0 && (!values?.[`dataSource${item}`]?.value || !values?.[`dataValue${item}`]?.value))
          && (values?.[`dataType${item}`]?.value === 1 && (!values?.[`dataSource${item}`]?.value || !values?.[`equipment${item}`]?.value || !values?.[`dataValue${item}`]?.value))
        )
        // || !values?.[`dataColor${item}`]?.hex
        || values?.[`axisControl${item}`]?.value === undefined
      ) {
        hasAllValues = false;
      }
      if (values?.[`dataType${item}`]?.value === 1) {
        const equipment = document.getElementById(`equipment${item}`);
        if (equipment) {
          if (!values?.[`equipment${item}`]?.value) {
            equipment.style.border = errorBorder;
          } else {
            equipment.style.border = validBorder;
          }
        }
      }
      const axisControl = document.getElementById(`axisControl${item}`);
      if (axisControl) {
        if (values?.[`axisControl${item}`]?.value === undefined) {
          axisControl.style.border = errorBorder;
        } else {
          axisControl.style.border = validBorder;
        }
      }
      const dataSource = document.getElementById(`dataSource${item}`);
      if (dataSource) {
        if (!values?.[`dataSource${item}`]?.value) {
          dataSource.style.border = errorBorder;
        } else {
          dataSource.style.border = validBorder;
        }
      }
      const dataValue = document.getElementById(`dataValue${item}`);
      if (dataValue) {
        if (!values?.[`dataValue${item}`]?.value) {
          dataValue.style.border = errorBorder;
        } else {
          dataValue.style.border = validBorder;
        }
      }
    });
    return hasAllValues;
  };

  const initialYRefVal = chartToEdit?.yRefVal;
  const initialYRefVal2 = chartToEdit?.yRefVal2;
  const initialYRefValColor = chartToEdit?.yRefValColor === null ? null : { hex: chartToEdit?.yRefValColor };
  const initialYRefVal2Color = chartToEdit?.yRefVal2Color === null ? null : { hex: chartToEdit?.yRefVal2Color };

  return (
    <div
      id="mainTrendContainer"
      css={[styles.main, styles.hideScrollbar]}
    >
      <div css={styles.topRow}>
        <div css={css`margin: 10px 10px 0px 10px; position: relative; border-radius: 4px; pointer-events: none;`}>
          <div id="notchedContainer" css={[styles.notchedContainer, css`background-color: rgba(215,215,215,1);`]}>
            <div css={styles.notch}>
              <div css={styles.label}>{language.quickFilters}</div>
            </div>
            <div css={css`width: 100%; height: 100%; border-radius: 4px;`}>
              {quickFiltersOptions.map((item) => (
                <button
                  css={quickFilter === item && activeFilter === 1 ? styles.selectedButton : styles.button}
                  type="button"
                  key={item}
                  onClick={() => {
                    setFilter(item);
                    setActiveFilter(1);
                  }}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div css={css`margin: 10px 5px 0px 5px; display: flex; pointer-events: none;`}>
          <div css={css`background-color: rgba(215,215,215,1); border-radius: 4px; height: fit-content; pointer-events: auto;`}>
            <DateTimePicker
              label="Start Date"
              ampm={false}
              showTodayButton
              inputVariant="outlined"
              value={startDate}
              maxDate={endDate || new Date()}
              onChange={(date) => { setStartDate(date); setActiveFilter(2); }}
            />
          </div>
          <div css={css`width: 5px;`} />
          <div css={css`background-color: rgba(215,215,215,1); border-radius: 4px; height: fit-content; pointer-events: auto;`}>
            <DateTimePicker
              label="End Date"
              ampm={false}
              showTodayButton
              inputVariant="outlined"
              value={endDate}
              disabled={!startDate}
              minDate={startDate}
              maxDate={new Date()}
              onChange={(date) => { setEndDate(date); setActiveFilter(2); }}
            />
          </div>
        </div>
        <div css={css`margin: 10px 10px 0px 10px; pointer-events: none;`}>
          <div id="notchedContainer" css={[styles.notchedContainer, css`background-color: rgb(215,215,215);`]}>
            <div css={styles.smallNotch}>
              <div css={styles.label}>{language.actions}</div>
            </div>
            <div css={css`width: 100%; height: 100%; border-radius: 4px; display: flex;`}>
              {(IOValueOptions.length > 0 || weatherValueOptions.length > 0)
                && (
                <button
                  css={styles.addTrendButtonMain}
                  type="button"
                  onClick={() => {
                    setIsAddingChart(true);
                  }}
                >
                  <FaPlus css={styles.icon} />
                  {language.trend}
                </button>
                )}
              {isPrinting
                ? (
                  <div css={styles.addTrendButtonMain}>
                    <FaSpinner css={styles.icon} />
                    {language.loading}...
                  </div>
                )
                : (
                  <button
                    css={styles.addTrendButtonMain}
                    type="button"
                    onClick={() => {
                      printImage();
                    }}
                  >
                    <FaDownload css={styles.icon} />
                    {language.dashboard}
                  </button>
                )}
              {(props.trends?.datasets && Object.values(props.trends?.datasets).length > 0)
                && (
                <button
                  css={styles.addTrendButtonMain}
                  type="button"
                  onClick={() => {
                    downloadCSV();
                  }}
                >
                  <FaDownload css={styles.icon} />
                  {language.data}
                </button>
                )}
            </div>
          </div>
        </div>
      </div>
      <div id="divToPrint" css={[trendsLoading || statsLoading ? {} : css`height: fit-content;`]}>
        {(statsLoading || trendsLoading) && (
          <div css={css`width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center;`}>
            <div css={[styles.trendsStat, css`position: relative; min-height: 60px; margin-top: 50px;`]}>
              <div css={styles.loaderContainer}>
                <div css={styles.spinningLoader} />
              </div>
            </div>
            <div css={[styles.trendsStat, css`position: relative; margin-top: 5px; color: rgb(10, 188, 201); font-family: Roboto, Helvetica, Arial, sans-serif;`]}>
              <div css={styles.textContainer}>
                {loadingText}
              </div>
            </div>
          </div>
        )}
        <div css={[css`display: ${isPrinting ? 'flex' : 'none'};`, styles.printLogoContainer]}>
          <img css={css`height: 50px; margin: 7px;`} src={hkdLogo} alt="hkdLogo" />
          <div css={css`display: flex; flex-direction: column; flex-wrap: wrap; font-size: 24px; color: #95a1aa; margin: 7px;`}>
            <div>{`${language.reportCreated}:`}</div>
            <div>{`(${moment().local().format('YYYY-MM-DD kk:mm')})`}</div>
          </div>
          <div css={css`font-size: 24px; color: #95a1aa; margin: 7px;`}>
            <div css={css`margin-right: 3px;`}>{`${language.dateRange}:`}</div>
            {(activeFilter === 1 || !startDate || !endDate)
              ? quickFilter
              : (
                <div css={css`width: 100%; display: flex; flex-wrap: wrap;`}>
                  <div css={css`margin-right: 3px;`}>{`(${startDate.format('YYYY-MM-DD kk:mm')}) to `}</div>
                  <div>{`(${endDate.format('YYYY-MM-DD kk:mm')})`}</div>
                </div>
              )}
          </div>
        </div>
        {!statsLoading && !trendsLoading
          && (
            <div css={styles.trendsStat}>
            {/*   <CardStat */}
            {/*     title="Total Gallons Flowed" */}
            {/*     timeString1={activeFilter === 1 ? quickFilter : startDate?.format('MM/DD/YY')} */}
            {/*     timeString2={activeFilter === 1 ? null : endDate?.format('MM/DD/YY')} */}
            {/*     mainStatistic={props.trends.totalGallons?.majorPeriod} */}
            {/*     subStatistic={props.trends.totalGallons?.minorPeriod} */}
            {/*     trending={Math.round(props.trends.totalGallons?.minorPeriod)} */}
            {/*   /> */}
              {/* <CardStat
                title={language.snowmakingRunHours}
                timeString1={activeFilter === 1 ? quickFilter : startDate?.format('MM/DD/YY')}
                timeString2={activeFilter === 1 ? null : endDate?.format('MM/DD/YY')}
                mainStatistic={props.trends.snowmakingHours?.majorPeriod}
                subStatistic={props.trends.snowmakingHours?.minorPeriod}
                trending={Math.round(props.trends.snowmakingHours?.minorPeriod)}
              /> */}
              {/* <CardStat
                title="Avg. SEI (kWh/1000gal)"
                timeString1={activeFilter === 1 ? quickFilter : startDate?.format('MM/DD/YY')}
                timeString2={activeFilter === 1 ? null : endDate?.format('MM/DD/YY')}
                mainStatistic={props.trends.averageSei?.majorPeriod}
                subStatistic={props.trends.averageSei?.minorPeriod}
                trending={-Math.round(props.trends.averageSei?.minorPeriod)}
              /> */}
              {/* <CardStat
                title={language.trailsWithSnowmaking}
                timeString1={activeFilter === 1 ? quickFilter : startDate?.format('MM/DD/YY')}
                timeString2={activeFilter === 1 ? null : endDate?.format('MM/DD/YY')}
                mainStatistic={props.trends.snowmakingTrails?.majorPeriod}
                subStatistic={props.trends.snowmakingTrails?.minorPeriod}
                trending={Math.round(props.trends.snowmakingTrails?.minorPeriod)}
              /> */}
            </div>
          )}
        {!statsLoading && !trendsLoading && (
          <div css={styles.trendsChart}>
            { isTrashingChart
              ? (
                <div css={[styles.trashTrendPopUp, styles.hideScrollbar]}>
                  <div css={css`width: 80%;`}>
                    <CardChart
                      title={chartToDelete.title}
                      data={chartToDelete.chartJsData}
                      key={chartToDelete.gridPosition}
                      options={chartToDelete.options}
                      renderType="addChart"
                    />
                  </div>
                  <div className="button" css={styles.addTrendButtonGroup}>
                    <button type="button"
                      css={styles.trashTrend}
                      onClick={() => (handleDelete())}
                    ><FaMinusCircle css={styles.buttonIcon} /> {language.delete}
                    </button>
                    <button type="button" css={styles.cancelTrashTrend} onClick={() => setIsTrashingChart(!isTrashingChart)}><FaArrowCircleLeft css={styles.buttonIcon} /> {language.cancel}</button>
                  </div>
                </div>
              ) : null }
            {sortedTrends.map((item) => {
              return (
                <div key={`container${sortedTrends.indexOf(item)}`} css={[styles.chartSizing, props.navbarClosed ? styles.chartSizingClosed : styles.chartSizingOpen]}>
                  <CardChart
                    title={item.title}
                    data={item.chartJsData}
                    key={sortedTrends.indexOf(item)}
                    options={item.options}
                    setIsEditingChart={setLoadingEditing}
                    item={item}
                    setChartToEdit={setChartToEdit}
                    edit={() => {
                      setChartToEdit(item);
                      setLoadingEditing(true);
                    }}
                    trash={() => {
                      setChartToDelete(item);
                      setIsTrashingChart(true);
                    }}
                  />
                </div>
              );
            })}
            {isAddingChart
              ? (
                <div css={styles.addTrendFullscreen}>
                  <div css={[css`height: 100%; overflow-y: scroll; display: flex; flex-direction: column;`, styles.hideScrollbar]}>
                    <div css={styles.addTrendTitleArea}>
                      <h1>{language.addNewTrend}</h1>
                    </div>
                    <div css={styles.addTrendMainArea}>
                      <Form
                        onSubmit={onSubmit}
                        validate={(values) => {
                          const errors = {};
                          if (!hasTrendDataValues(values)) {
                            errors.data = ' *';
                          }
                          return errors;
                        }}
                        render={({
                          handleSubmit,
                          form,
                          submitting,
                          valid,
                          pristine,
                          values,
                        }) => (
                          <form
                            onSubmit={handleSubmit}
                            css={styles.addTrendForm}
                            autoComplete="off"
                          >
                            <FaTimes css={styles.iconTimes} onClick={() => { setIsAddingChart(!isAddingChart); }} size={42} />
                            <div css={styles.addTrendFormBorder}>
                              <div css={css`margin: 12px 12px 0px 12px; display: flex; flex-direction: column; max-width: fit-content;`}>
                                <div css={css`padding: 0px 10px 0px 0px; display: flex; flex-direction: column; align-items: flex-start;`}>
                                  <div css={styles.singleInput}>
                                    {/* <div css={css`margin-right: 10px; width: ${pristine || submitting ? 143 : 135}px;`}>Title: </div> */}
                                    <div css={css`margin-right: 10px; width: 144px;`}>{`${language.title}: `}</div>
                                    <Field name="title">
                                      {({ input, meta }) => (
                                        <div css={css`display: flex; align-items: center;`}>
                                          <input
                                            data-lpignore
                                            id="title"
                                            css={css`
                                              height: 28px;
                                              border-color: #9e9e9e;
                                              border-radius: 4px;
                                              border-style: solid;
                                              border-width: 1px;
                                            `}
                                            name={input.name}
                                            value={input.value}
                                            onChange={input.onChange}
                                            type="text"
                                            placeholder={language.enterChartTitle}
                                          />
                                          {/* {meta.error && <span css={css`margin-left: 5px;`}>{meta.error}</span>} */}
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                  <div css={styles.singleInput}>
                                    <div css={css`margin-right: 10px; width: 143px;`}>{`${language.gridPosition}: `}</div>
                                    <Field name="gridPosition" component="input" type="number">
                                      {({ input, meta }) => (
                                        <div css={css`display: flex; align-items: center;`}>
                                          <input
                                            data-lpignore
                                            id="gridPosition"
                                            css={css`
                                              height: 28px;
                                              border-color: #9e9e9e;
                                              border-radius: 4px;
                                              border-style: solid;
                                              border-width: 1px;
                                            `}
                                            name={input.name}
                                            value={input.value}
                                            onChange={input.onChange}
                                            type="number"
                                            placeholder={language.enterGridPosition}
                                          />
                                          {/* {meta.error && <span css={css`margin-left: 5px;`}>{meta.error}</span>} */}
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              </div>
                              <div css={css`margin: 0px 12px 0px 12px; display: flex; flex-direction: column; max-width: fit-content;`}>
                                <div css={css`padding: 0px 10px 12px 0px; display: flex; flex-direction: column; align-items: flex-start;`}>
                                  <div css={styles.singleInput}>
                                    <div css={css`margin-right: 10px;`}>{`${language.referenceValue} Y1: `}</div>
                                    <div css={styles.formDataContainer}>
                                      <div css={css`margin: 0px 14px 0px 0px;`}>
                                        <Field
                                          css={css`
                                          height: 28px;
                                          border-color: #9e9e9e;
                                          border-radius: 4px;
                                          border-style: solid;
                                          border-width: 1px;
                                        `}
                                          name="referenceValueY1"
                                          component="input"
                                          type="number"
                                          placeholder={`${language.referenceValue}...`}
                                        />
                                      </div>
                                      <div css={css`min-width: 238px; margin-top: 5px;`}>
                                        <Field
                                          name="referenceColorY1"
                                          component={ColorPickerAdapter}
                                          initialValue="#808080"
                                          colors={colors}
                                          width="fit-content"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div css={styles.singleInput}>
                                    <div css={css`margin-right: 10px;`}>{`${language.referenceValue} Y2: `}</div>
                                    <div css={styles.formDataContainer}>
                                      <div css={css`margin: 0px 14px 0px 0px;`}>
                                        <Field
                                          css={css`
                                          height: 28px;
                                          border-color: #9e9e9e;
                                          border-radius: 4px;
                                          border-style: solid;
                                          border-width: 1px;
                                        `}
                                          name="referenceValueY2"
                                          component="input"
                                          type="number"
                                          placeholder={`${language.referenceValue}...`}
                                        />
                                      </div>
                                      <div css={css`min-width: 238px; margin-top: 5px;`}>
                                        <Field
                                          name="referenceColorY2"
                                          component={ColorPickerAdapter}
                                          initialValue="#808080"
                                          colors={colors}
                                          width="fit-content"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div css={css`display: flex; max-width: 100%; flex-wrap: wrap;`}>
                                <div css={css`min-width: fit-content; margin: 0px 6px 0px 6px; display: flex; flex-direction: column; width: 100%;`}>
                                  <div css={styles.lightGreyBackground}>
                                    {/* {renderAddChartData(styles, weatherStationOptions, axisOptions, ioSelectStyles, IOValueOptions, weatherValueOptions, colors, language, trendDataArray, setTrendDataArray, values, locationOptions, equipmentOptions, ioOptions, form)} */}
                                    {renderAddChartData(values, form)}
                                    <button
                                      css={styles.addDataButton}
                                      type="button"
                                      disabled={trendDataArray[1].length > 5}
                                      onClick={() => {
                                        if (trendDataArray[1].length < 6) {
                                          const tempArray = trendDataArray[1];
                                          const number = trendDataArray[1][trendDataArray[1].length - 1] + 1;
                                          tempArray.push(Number.isNaN(number) ? 0 : number);
                                          setTrendDataArray({ 1: tempArray });
                                          hasTrendDataValues(values);
                                        }
                                      }}
                                    >
                                      {trendDataArray[1].length < 6 ?
                                        (
                                        <div css={css`width: fit-content; display: flex;`}>
                                          <FaPlus css={styles.icon} />
                                          {language.addData}
                                        </div>
                                        ) : 
                                        (
                                        <div css={css`width: fit-content; display: flex;`}>
                                          {language.maxDataReached}
                                        </div>
                                        )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div css={css`width: 100%; display: flex; justify-content: center;`}>
                                <div css={styles.previewContainer}>
                                  <div className="button" css={styles.addTrendButtonGroup}>
                                    <button
                                      css={valid === true ? styles.addTrendButtonFullscreen : styles.addTrendButtonFullscreenDisabled}
                                      // css={styles.addTrendButtonFullscreen}
                                      type="submit"
                                      disabled={submitting || pristine}
                                    >
                                      <FaPlus />
                                      {language.addTrend}
                                    </button>
                                    <button
                                      css={styles.resetButtonFullscreen}
                                      type="button"
                                      onClick={() => setIsAddingChart(!isAddingChart)}
                                    >
                                      <FaArrowCircleLeft />
                                      {language.back}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        )}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            {loadingEditing
              ? (
                <div css={styles.addTrendFullscreen}>
                  <div css={css`width: 100%; max-height: 400px;`}>
                    <div css={css`width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center;`}>
                      <div css={[styles.trendsStat, css`position: relative; min-height: 60px; margin-top: 50px;`]}>
                        <div css={styles.loaderContainer}>
                          <div css={styles.spinningLoader} />
                        </div>
                      </div>
                      <div css={[styles.trendsStat, css`position: relative; margin-top: 5px; color: rgb(10, 188, 201); font-family: Roboto, Helvetica, Arial, sans-serif;`]}>
                        <div css={styles.textContainer}>
                         {language.loadingEditor} 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            { isEditingChart
              ? (
                <div css={styles.addTrendFullscreen}>
                  <div css={[css`height: 100%; overflow-y: scroll; display: flex; flex-direction: column;`, styles.hideScrollbar]}>
                    <div css={styles.addTrendTitleArea}>
                      <h1>{language.editTrend}</h1>
                    </div>
                    <div css={styles.addTrendMainArea}>
                      <Form
                        onSubmit={onEditSubmit}
                        validate={(values) => {
                          const errors = {};
                          if (!hasTrendDataValues(values)) {
                            errors.data = ' *';
                          }
                          return errors;
                        }}
                        render={({
                          handleSubmit,
                          form,
                          submitting,
                          valid,
                          pristine,
                          values,
                        }) => (
                          <form
                            onSubmit={handleSubmit}
                            css={styles.addTrendForm}
                            autoComplete="off"
                          >
                            <FaTimes css={styles.iconTimes} onClick={() => { setIsEditingChart(!isEditingChart); }} size={42} />
                            <div css={styles.addTrendFormBorder}>
                              <div css={css`margin: 12px 12px 0px 12px; display: flex; flex-direction: column; max-width: fit-content;`}>
                                <div css={css`padding: 0px 10px 0px 0px; display: flex; flex-direction: column; align-items: flex-start;`}>
                                  <div css={styles.singleInput}>
                                    <div css={css`margin-right: 10px; width: 144px;`}>{`${language.title}: `}</div>
                                    <Field
                                      name="title"
                                      initialValue={chartToEdit.title}
                                    >
                                      {({ input, meta }) => (
                                        <div css={css`display: flex; align-items: center;`}>
                                          <input
                                            data-lpignore
                                            id="title"
                                            css={css`
                                              height: 28px;
                                              border-color: #9e9e9e;
                                              border-radius: 4px;
                                              border-style: solid;
                                              border-width: 1px;
                                            `}
                                            name={input.name}
                                            value={input.value}
                                            onChange={input.onChange}
                                            type="text"
                                            placeholder={language.enterChartTitle}
                                          />
                                          {/* {meta.error && <span css={css`margin-left: 5px;`}>{meta.error}</span>} */}
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                  <div css={styles.singleInput}>
                                    <div css={css`margin-right: 10px; width: 143px;`}>{`${language.gridPosition}: `}</div>
                                    <Field
                                      name="gridPosition"
                                      component="input"
                                      type="number"
                                      initialValue={chartToEdit.gridPosition}
                                    >
                                      {({ input, meta }) => (
                                        <div css={css`display: flex; align-items: center;`}>
                                          <input
                                            data-lpignore
                                            id="gridPosition"
                                            css={css`
                                              height: 28px;
                                              border-color: #9e9e9e;
                                              border-radius: 4px;
                                              border-style: solid;
                                              border-width: 1px;
                                            `}
                                            name={input.name}
                                            value={input.value}
                                            onChange={input.onChange}
                                            type="number"
                                            placeholder={language.enterGridPosition}
                                          />
                                          {/* {meta.error && <span css={css`margin-left: 5px;`}>{meta.error}</span>} */}
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              </div>
                              <div css={css`margin: 0px 12px 0px 12px; display: flex; flex-direction: column; max-width: fit-content;`}>
                                <div css={css`padding: 0px 10px 12px 0px; display: flex; flex-direction: column; align-items: flex-start;`}>
                                  <div css={styles.singleInput}>
                                    <div css={css`margin-right: 10px;`}>{`${language.referenceValue} Y1: `}</div>
                                    <div css={styles.formDataContainer}>
                                      <div css={css`margin: 0px 14px 0px 0px;`}>
                                        <Field
                                          css={css`
                                          height: 28px;
                                          border-color: #9e9e9e;
                                          border-radius: 4px;
                                          border-style: solid;
                                          border-width: 1px;
                                        `}
                                          name="referenceValueY1"
                                          component="input"
                                          type="number"
                                          initialValue={initialYRefVal}
                                          placeholder={language.referenceValue}
                                        />
                                      </div>
                                      <div css={css`min-width: 238px; margin-top: 5px;`}>
                                        <Field
                                          name="referenceColorY1"
                                          component={ColorPickerAdapter}
                                          colors={colors}
                                          initialValue={initialYRefValColor}
                                          width="fit-content"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div css={styles.singleInput}>
                                    <div css={css`margin-right: 10px;`}>{`${language.referenceValue} Y2: `}</div>
                                    <div css={styles.formDataContainer}>
                                      <div css={css`margin: 0px 14px 0px 0px;`}>
                                        <Field
                                          css={css`
                                          height: 28px;
                                          border-color: #9e9e9e;
                                          border-radius: 4px;
                                          border-style: solid;
                                          border-width: 1px;
                                        `}
                                          name="referenceValueY2"
                                          component="input"
                                          type="number"
                                          initialValue={initialYRefVal2}
                                          placeholder={language.referenceValue}
                                        />
                                      </div>
                                      <div css={css`min-width: 238px; margin-top: 5px;`}>
                                        <Field
                                          name="referenceColorY2"
                                          component={ColorPickerAdapter}
                                          colors={colors}
                                          initialValue={initialYRefVal2Color}
                                          width="fit-content"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div css={css`display: flex; max-width: 100%; flex-wrap: wrap;`}>
                                <div css={css`min-width: fit-content; margin: 0px 6px 0px 6px; display: flex; flex-direction: column; width: 100%;`}>
                                  <div css={styles.lightGreyBackground}>
                                    {renderEditChartData(values, form)}
                                    <button
                                      css={styles.addDataButton}
                                      type="button"
                                      disabled={trendDataArray[1].length > 5}
                                      onClick={() => {
                                        if (trendDataArray[1].length < 6) {
                                          const tempArray = trendDataArray[1];
                                          const number = trendDataArray[1][trendDataArray[1].length - 1] + 1;
                                          tempArray.push(Number.isNaN(number) ? 0 : number);
                                          setTrendDataArray({ 1: tempArray });
                                          hasTrendDataValues(values);
                                        }
                                      }}
                                    >
                                      {trendDataArray[1].length < 6 ?
                                        (
                                        <div css={css`width: fit-content; display: flex;`}>
                                          <FaPlus css={styles.icon} />
                                          {language.addData}
                                        </div>
                                        ) : 
                                        (
                                        <div css={css`width: fit-content; display: flex;`}>
                                          {language.maxDataReached}
                                        </div>
                                        )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div css={css`width: 100%; display: flex; justify-content: center;`}>
                                <div css={styles.previewContainer}>
                                  <div className="button" css={styles.addTrendButtonGroup}>
                                    <button
                                      css={valid === true ? styles.addTrendButtonFullscreen : styles.addTrendButtonFullscreenDisabled}
                                      // css={styles.addTrendButtonFullscreen}
                                      type="submit"
                                      disabled={submitting || pristine}
                                    >
                                      <FaPlus />
                                      {language.editTrend}
                                    </button>
                                    <button
                                      css={styles.resetButtonFullscreen}
                                      type="button"
                                      onClick={() => setIsEditingChart(!isEditingChart)}
                                    >
                                      <FaArrowCircleLeft />
                                      {language.back}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        )}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

const stylesFromTheme = (theme) => {
  return {
    button: css`
      pointer-events: auto;
      margin-left: 2px;
      margin-right: 2px;
      margin-top: 4px;
      padding: 4px;
      background: #e9e9e9;
      border: 1px solid #000000;
      border-radius: 2px;
      font-family: Open Sans;
      height: 30px;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #000000
      :hover {
        box-shadow: 0 0 8px;
      }
    `,
    selectedButton: css`
      pointer-events: auto;
      margin-left: 2px;
      margin-right: 2px;
      margin-top: 4px;
      padding: 4px;
      height: 30px;
      background: #474747;
      border: 1px solid #000000;
      border-radius: 2px;
      font-family: Open Sans;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #FFFFFF;
    `,
    addTrendButtonFullscreen: css`
      background: #027600;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: row;
      // border: 2px solid #363636;
      color: white;
      margin-top: 8px;
      font-size: 16px;
      font-family: Open Sans;
      cursor: pointer;
      border-radius: 4px;
      width: 120px;
      height: 45px;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    `,
    addTrendButtonFullscreenDisabled: css`
      background: #639462;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: row;
      // border: 2px solid #363636;
      color: white;
      margin-top: 8px;
      font-size: 16px;
      font-family: Open Sans;
      cursor: no-drop;
      border-radius: 4px;
      width: 120px;
      height: 45px;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    `,
    resetButtonFullscreen: css`
      background-color:#7B7B7B;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: row;
      // border: 1px solid grey;
      color: white;
      margin-top: 8px;
      margin-left: 6px;
      font-size: 16px;
      font-family: Open Sans;
      cursor: pointer;
      border-radius: 4px;
      width: 120px;
      height: 45px;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    `,
    addDataButton: css`
      background-color: rgb(160, 160, 160);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      color: white;
      border-radius: 2px;
      padding: 3px 8px 3px 8px;
      font-size: 14px;
      font-family: Open Sans;
      cursor: pointer;
      height: 30px;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
      margin: 5px;
      :hover {
        color: rgb(215, 215, 215);
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
      }
    `,
    addTrendButtonMain: css`
      pointer-events: auto;
      background-color:#7B7B7B;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      color: white;
      margin-top: 4px;
      padding: 3px 5px 3px 5px;
      border-color: black;
      border-style: solid;
      border-width: 1px;
      margin-left: 6px;
      font-size: 14px;
      font-family: Open Sans;
      cursor: pointer;
      border-radius: 4px;
      // width: 90px;
      height: 30px;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    `,
    input: css`
      width: 49px;
      height: 30px;
      margin-left: 10px;
      margin-bottom: 3px;
      margin-top: 4px;
      padding: 7.8px;
      border: 1px solid #000000;
      border-radius: 2px;
      font-family: Open Sans;
      font-weight: 600;
      font-size: 14px;
    `,
    dropdown: css`
      margin-left: 4px;
      margin-top: 4px;
      height: 30px;
      background-color: #FFFFFF;
      border-radius: 2px;
      border: 1px solid #000000;
      font-size: 14px;
    `,
    activeDropdown: css`
      margin-left: 4px;
      margin-top: 4px;
      height: 30px;
      background-color: #474747;
      color: white;
      border-radius: 2px;
      border: 1px solid #000000;
      font-size: 14px;
    `,
    main: css`
      position: relative;
      background-color: #D7D7D7;
      box-sizing: border-box;
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
    `,
    topRow: css`
      pointer-events: none;
      background-color: transparent;
      position: sticky; 
      top: 0px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        margin-left: 20px;
        display: block;
      }
    `,
    trendsStat: css`
      display: flex;
      align-items: left;
      flex: 1;
      flex-wrap: wrap;
      margin-left: 3px;
      margin-right: 3px;
      margin-top: 3px;
      margin-bottom: 3px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        margin-left: 20px;
      }
    `,
    trendsChart: css`
      display: flex;
      // align-items: flex-start;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-left: 3px;
      margin-right: 3px;
      margin-top: 3px;
      margin-bottom: 3px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        margin-left: 20px;
      }
    `,
    addTrendFullscreen: css`
      position: fixed; 
      display: flex;
      flex: 1;
      justify-content: center;
      flex-direction: column;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: #3C3C3C;
      color: white;
      z-index: 6;
      // overflow-y: scroll;
    `,
    trashTrendPopUp: css`
      position: fixed;
      display: flex;
      flex: 1;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: #3C3C3C;
      color: white;
      z-index: 6;
      overflow-y: scroll;
    `,
    addTrendMainArea: css`
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      max-width: 100%;
    `,
    addTrendTitleArea: css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 12px;
    `,
    addTrendRowsOfItems: css`
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    `,
    addTrendArea: css`
      display: flex;
      justify-content: center;
      flex-direction: row;
    `,
    trashTrendMainArea: css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `,
    inputLabel: css`
      font-size: 16px;
      margin-right: 5px;
      margin-left: 5px;
    `,
    // eyeDropperIcon: css`
    //   font-size: 14px;
    //   margin-right: 5px;
    //   margin-left: 10px;
    // `,
    singleInput: css`
      margin: 10px 10px 0px 0px;
      font-size: medium;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
    `,
    addTrendButtonGroup: css`
      display: flex;
      flex: 1;
      justify-content: center;
      flex-direction: row;
      margin-top: 8px;
      margin-bottom: 8px;
      max-height: 50px;
    `,
    trashTrend: css`
      margin-right: 15px;
      display: flex;
      justify-content: space-around;
      padding: 10px;
      background: #BC0000;
      border: 2px solid #750303;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 22px;
      border-radius: 10px;
    `,
    cancelTrashTrend: css`
      display: flex;
      justify-content: space-around;
      padding: 10px;
      background: rgba(165, 165, 165, 0.82);
      border: 2px solid rgba(165, 165, 165, 0.82);
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 22px;
      border-radius: 10px;
    `,
    previewContainer: css`
      display: flex;
      flex-direction: column;
      max-width: 420px;
      margin: 12px 12px 0px 12px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        max-width: calc(100vw - 24px); // need explicit units for chartjs sizing
      }
    `,
    preview: css`
      align-self: center;
    `,
    addTrendForm: css`
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      width: fit-content;
      max-width: 100%;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        align-items: flex-start;
      }
    `,
    addTrendFormBorder: css`
      display: flex;
      flex-direction: column;
      max-width: fit-content;
      border-radius: 5px;
      border: 1px solid white;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        border: 0px;
      }
    `,
    formDataContainer: css`
      display: flex;
      // margin-right: 14px;
      margin-right: 4px;
      flex-wrap: wrap;
      // width: 100%;
    `,
    formDataBackground: css`
      padding: 0px 10px 10px 10px;
      margin: 5px;
      background-color: rgb(160,160,160);
      position: relative;
      border-radius: 2px;
      min-width: 615px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: 0px;
      }
    `,
    closeIcon: css`
      display: flex;
      align-self: right;
    `,
    closeIconRow: css`
      display: flex;
      justify-content: flex-end;
    `,
    icon: css`
      margin-right: 7px;
      font-size: 14px;
    `,
    buttonIcon: css`
      margin-right: 5px;
    `,
    green: css`
      height: 400px;
    `,
    trashCan: css`
      margin-top: 3px;
      margin-left: 3px;
    `,
    notchedContainer: css`
      pointer-events: none;
      position: relative;
      border-radius: 4px;
      padding: 10px;
      box-sizing: padding-box;
      position: relative;
      border-right: 1px solid rgba(0,0,0,0.23);
      border-bottom: 1px solid rgba(0,0,0,0.23);
      border-left: 1px solid rgba(0,0,0,0.23);
      width: fit-content;
      #notchedContainer:hover {
        border-color: rgba(0, 0, 0, 0.87);
      }
      &:hover > :before {
        border-color: rgba(0,0,0,0.87);
      }
      &:hover > :after {
        border-color: rgba(0,0,0,0.87);
      }

      :hover {
        border-color: rgba(0,0,0,0.87);
      }
    `,
    smallNotch: css`
      display: flex;
      flex-direction: row;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      ::before {
        border-radius: 4px 0 0 0;
        display: block;
        content: "";
        flex: 1 0 0;
        height: 1px;
        border-top: 1px solid rgba(0,0,0,0.23);
      }
      ::after {
        border-radius: 0 4px 0 0;
        display: block;
        content: "";
        flex: 30 0 0;
        height: 1px;
        border-top: 1px solid rgba(0,0,0,0.23);
      }
    `,
    notch: css`
      display: flex;
      flex-direction: row;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      ::before {
        border-radius: 4px 0 0 0;
        display: block;
        content: "";
        flex: 1 0 0;
        height: 1px;
        border-top: 1px solid rgba(0,0,0,0.23);
      }
      ::after {
        border-radius: 0 4px 0 0;
        display: block;
        content: "";
        flex: 20 0 0;
        height: 1px;
        border-top: 1px solid rgba(0,0,0,0.23);
      }
    `,
    label: css`
      height: 20px;
      background-color: rgba(215,215,215,1);
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.54);
      font-size: 1rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      letter-spacing: 0.00938em;
      transform: scale(0.75);
      flex: 0 0 80px;
      margin-top: -10px;
      text-align: center;
      white-space: nowrap;
    `,
    loaderContainer: css`
      position: absolute;
      top: 60;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 4;
    `,
    textContainer: css`
      position: absolute;
      top: 60;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 4;
    `,
    spinningLoader: css`
      width: 50px;
      height: 50px;
      border: 5px solid rgba(10, 188, 201, 0.4);
      border-left-color: rgb(10, 188, 201);
      border-radius: 50%;
      background: transparent;
      animation-name: rotate-s-loader;
      animation-iteration-count: infinite;
      animation-duration: 1s;
      animation-timing-function: linear;
      position: relative;
      @keyframes rotate-s-loader {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      // -ms-overflow-style: none;  /* Internet Explorer 10+ */
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
      }
      scrollbar-width: none; /* Firefox */
    `,
    chartSizing: css`
      // explicit width height calculation prevents charts from
      // collapsing when they have no data (i.e during a render) that would
      // otherwise result in the page scrolling to the top
      transition: width 0.4s, height 0.4s; 
      min-width: 400px;
      min-height: 234px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        width: 100%;
        min-width: 0px;
        height: calc(100vw/1.75);
      }
    `,
    chartSizingOpen: css`
      --navOpenWidth: calc(50vw - 83px);
      width: var(--navOpenWidth);
      height: calc(var(--navOpenWidth)/1.75);
    `,
    chartSizingClosed: css`
      --navClosedWidth: calc(50vw - 11px);
      width: var(--navClosedWidth);
      height: calc(var(--navClosedWidth)/1.75);
    `,
    iconTimes: css`
      color: white;
      margin: 4px 4px 0px 0px;
      position: absolute;
      top: 0px;
      right: 0px;
      cursor: pointer;
      :hover {
        color: rgb(215, 215, 215);
      }
    `,
    lightGreyBackground: css`
      display: flex;
      flex-direction: column;
      background-color: rgb(90,90,90);
      border-radius: 4px;
      width: 100%;
      min-width: 625px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: 100%;
      }
    `,
    printLogoContainer: css`
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 15px;
      }
    `,
    actionBlocked: css`
      cursor: not-allowed;
    `,
  };
};

TrendScreen.propTypes = {
  trends: PropTypes.shape({
    trends: PropTypes.object,
    IOValues: PropTypes.object,
    totalGallons: PropTypes.object,
    snowmakingHours: PropTypes.object,
    snowmakingTrails: PropTypes.object,
    averageSei: PropTypes.object,
    datasets: PropTypes.object,
  }).isRequired,

  locations: PropTypes.shape({
    locations: PropTypes.object,
  }).isRequired,

  hydrants: PropTypes.shape({
    hydrants: PropTypes.object,
  }).isRequired,

  weatherStations: PropTypes.shape({
    weatherStations: PropTypes.object,
  }).isRequired,

  navbarClosed: PropTypes.bool.isRequired,

  deleteTrend: PropTypes.func.isRequired,
  updateTrend: PropTypes.func.isRequired,
  addTrendWithDatasource: PropTypes.func.isRequired,
  updateTrendWithDatasource: PropTypes.func.isRequired,
  fetchAllTrends: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired,
  fetchAllIOValues: PropTypes.func.isRequired,
  fetchAllWeatherStations: PropTypes.func.isRequired,
  fetchTrendDataset: PropTypes.func.isRequired,
  fetchTrendDatasetDateRange: PropTypes.func.isRequired,
  // fetchTotalGallons: PropTypes.func.isRequired,
  // fetchAverageSei: PropTypes.func.isRequired,
  // fetchSnowmakingHours: PropTypes.func.isRequired,
  // fetchSnowmakingTrails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  trends: state.trends,
  locations: state.locations,
  hydrants: state.hydrants,
  navbarClosed: state.nav.navbarClosed,
  weatherStations: state.weatherStations,
});

export default connect(mapStateToProps, {
  fetchLocations,
  fetchAllTrends,
  fetchAllIOValues,
  fetchAllWeatherStations,
  // fetchTotalGallons,
  // fetchSnowmakingHours,
  // fetchSnowmakingTrails,
  // fetchAverageSei,
  fetchTrendDataset,
  fetchTrendDatasetDateRange,
  deleteTrend,
  updateTrend,
  addTrendWithDatasource,
  updateTrendWithDatasource,
})(TrendScreen);