import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

export function fetchAllTrackerEquipment(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/trackerequipment`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_ALL_TRACKER_EQUIPMENT_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.FETCH_TRACKER_EQUIPMENT_ERROR, payload: error });
      return error;
    }
  };
}

export function fetchTrackerEquipment(id) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/trackerequipment/${id}`,
        headers: { Authorization: `Bearer ${token}` },

      });
      dispatch({ type: ActionTypes.FETCH_TRACKER_EQUIPMENT_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_TRACKER_EQUIPMENT_ERROR, payload: error });
    }
  };
}

export function fetchAllDefenders(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/trackerequipment/defenders`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_ALL_DEFENDERS_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.FETCH_TRACKER_EQUIPMENT_ERROR, payload: error });
      return error;
    }
  };
}

// NOTE: We may want to move away from relying on the 1 second interval for this update.
// If we do, we will need to adjust the response.status to response.data and then retrieve the status
// in a different way (perhaps with an interceptor or a secondary dispatch).
export function addAutoHydrant(hydrantId, ipAddress, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'post',
        url: `${ROOT_URL}/trackerequipment/hydrant/${hydrantId}/${ipAddress}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.ADD_AUTO_HYDRANT_SUCCESS, payload: response.status });
      return response.status;
    } catch (error) {
      dispatch({ type: ActionTypes.AUTO_HYDRANT_ERROR, payload: error?.response?.status });
      return error?.response?.status;
    }
  };
}

// NOTE: We may want to move away from relying on the 1 second interval for this update.
// If we do, we will need to adjust the response.status to response.data and then retrieve the status
// in a different way (perhaps with an interceptor or a secondary dispatch).
export function deleteAutoHydrant(hydrantId) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'delete',
        url: `${ROOT_URL}/trackerequipment/hydrant/${hydrantId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.DELETE_AUTO_HYDRANT_SUCCESS, payload: response.status });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_TRACKER_EQUIPMENT_ERROR, payload: error?.response?.status });
      return error?.response;
    }
  };
}
