import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

export function fetchSettings() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/usersettings`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_SETTINGS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.SETTINGS_ERROR, payload: error });
    }
  };
}

export function updateSettings(data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/usersettings/update`,
        headers: { Authorization: `Bearer ${token}` },
        data,

      });
      dispatch({ type: ActionTypes.UPDATE_SETTINGS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.SETTINGS_ERROR, payload: error });
    }
  };
}

export function fetchGunModels() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/gunModels`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_GUN_MODELS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.GUN_MODELS_ERROR, payload: error });
    }
  };
}
// export function fetchGunModels() {
//   return async (dispatch) => {
//     try {
//       const token = localStorage.getItem(TOKEN_STRING);

//       const response = await axios({
//         method: 'get',
//         url: `${ROOT_URL}/gunModels`,
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       dispatch({ type: ActionTypes.FETCH_SETTINGS_SUCCESS, payload: response.data });
//     } catch (error) {
//       dispatch({ type: ActionTypes.SETTINGS_ERROR, payload: error });
//     }
//   };
// }
