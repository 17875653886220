import { ActionTypes } from '../actions/Shared';

const initialState = {
  plcs: null,
  ipAddresses: null,
};

const PLCReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_PLCS_SUCCESS:
      return {
        ...state,
        plcs: action.payload,
        ipAddresses: Object.values(action.payload).reduce((obj, plc) => {
          const tempObj = obj;
          tempObj[plc.id] = {
            plcId: plc.id,
            ipAddress: plc.ipAddress,
          };
          return tempObj;
        }, {}),
      };
    case ActionTypes.FETCH_PLC_SUCCESS:
      return {
        ...state,
        plcs: {
          ...state.plcs,
          [action.payload.id]: action.payload,
        },
      };
    case ActionTypes.FETCH_PLC_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default PLCReducer;
