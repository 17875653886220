import { ActionTypes, FEET_PER_METER } from '../actions/Shared';

const initialState = {
  elevation: null,
};

const MapReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ELEVATION_SUCCESS:
      return { ...state, elevation: action.payload?.[0]?.elevation * FEET_PER_METER };
    case ActionTypes.FETCH_ELEVATION_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default MapReducer;
