import { combineReducers } from 'redux';
import LocationReducer from './LocationReducer';
import EquipmentReducer from './EquipmentReducer';
import TrailsReducer from './TrailsReducer';
import HydrantsReducer from './HydrantsReducer';
import AuthReducer from './AuthReducer';
import TrendReducer from './TrendReducer';
import GunsReducer from './GunsReducer';
import NavbarReducer from './NavbarReducer';
import WeatherStationReducer from './WeatherStationReducer';
import ZonesReducer from './ZonesReducer';
import SettingsReducer from './SettingsReducer';
import PLCReducer from './PLCReducer';
import MapReducer from './MapReducer';
import TrackerEquipmentReducer from './TrackerEquipmentReducer';

const rootReducer = combineReducers({
  auth: AuthReducer,
  locations: LocationReducer,
  equipment: EquipmentReducer,
  trails: TrailsReducer,
  hydrants: HydrantsReducer,
  trends: TrendReducer,
  guns: GunsReducer,
  nav: NavbarReducer,
  weatherStations: WeatherStationReducer,
  zones: ZonesReducer,
  settings: SettingsReducer,
  plcs: PLCReducer,
  map: MapReducer,
  trackerEquipment: TrackerEquipmentReducer,
});

export default rootReducer;
