import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { ThemeProvider } from 'emotion-theming';
import { LightTheme } from 'isno/lib/themes';
// import { LanguageContext, Lang } from 'isno/lib/components/language';
import reducers from './reducers';
import App from './screens/App';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'normalize.css';
import 'isno/lib/styles.scss';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(thunk),
  ),
);

ReactDOM.render(
  <Provider store={store}>
    {/* Then from the settings level we would use setContext()
    to toggle Lang.english -> Lang.french */}
    {/* <LanguageContext.Provider value={Lang.english}> */}
    <ThemeProvider theme={LightTheme}>
      <App />
    </ThemeProvider>
    {/* </LanguageContext.Provider> */}
  </Provider>,
  document.getElementById('root'),
);
