import { ActionTypes } from '../actions/Shared';

const initialState = {
  guns: undefined,
};

const GunsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_GUNS_SUCCESS:
      return { ...state, guns: action.payload };
    case ActionTypes.FETCH_GUN_SUCCESS:
      return {
        ...state,
        guns: {
          ...state.guns,
          [action.payload.id]: {
            ...action.payload[action.payload.id],
            gunModels: {
              ...action.payload.gunModels,
            },
          },
        },
      };
    case ActionTypes.UPDATE_GUN_AVAILABLE_SUCCESS:
      return {
        ...state,
        guns: {
          ...state.guns,
          [action.payload.id]: {
            ...action.payload,
            // This is due to a weird postgress decision to not include Z in their UTC times stored in the database
            dateModified: action.payload?.dateModified.substring(0, action.payload?.dateModified.length - 2),
            availableStages: state.guns?.[action.payload.id]?.availableStages,
            gunModels: {
              ...state.guns?.[action.payload.id].gunModels,
            },
          },
        },
      };
    case ActionTypes.ADD_GUN_SUCCESS:
      return {
        ...state,
        guns: {
          ...state.guns,
          [action.payload.id]: {
            ...action.payload[action.payload.id],
            gunModels: {
              ...action.payload.gunModels,
            },
          },
        },
      };
    case ActionTypes.ADD_GUN_MODEL_SUCCESS:
      return {
        ...state,
        guns: {
          ...state.guns,
          [action.payload.gunId]: {
            ...state.guns[action.payload.gunId],
            gunModels: {
              ...state.guns[action.payload.gunId].gunModels,
              [action.payload.id]: action.payload,
            },
          },
        },
      };
    case ActionTypes.DELETE_GUN_MODEL_SUCCESS:
      return {
        ...state,
        guns: {
          ...state.guns,
          [action.payload.gunId]: {
            ...state.guns[action.payload.gunId],
            gunModels: {
              ...Object.values(state.guns[action.payload.gunId].gunModels).filter((item) => item.id !== action.payload.id),
            },
          },
        },
      };
    case ActionTypes.UPDATE_GUN_MODEL_SUCCESS:
      return {
        ...state,
        guns: {
          ...state.guns,
          [action.payload.gunId]: {
            ...state.guns[action.payload.gunId],
            gunModels: {
              ...state.guns[action.payload.gunId].gunModels,
              [action.payload.id]: action.payload,
            },
          },
        },
      };
    case ActionTypes.FETCH_GUNS_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default GunsReducer;
