import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pipe,
  Pond,
  Pump,
  Compressor,
  ValveConnect,
  PipePlaceholder,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowCustom,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Statistic,
  Info,
  MultiInfo,
  Alarms,
  PLCStateRouter,
  PLCColorRouter,
  MobileStatTable,
} from 'isno/lib/components/displays';

import {
  AlarmReset,
  ValvePID,
  InfoButton,
} from 'isno/lib/components/controls';

import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import PLC from 'isno/lib/static/images/PLC.png';
import GreenCompressor from 'isno/lib/static/images/Compressor/MUM_Compressor.png';
import GreenCompressorGlow from 'isno/lib/static/images/Compressor/MUM_Compressor_Glow.png';
import { fetchLocations, setIOValue, fetchPLCs } from '../actions';
import LoadingScreen from './LoadingScreen';

const EquipmentScreen = (props) => {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      // There are only 2 locations and 2 PLCS so we can fetch them all at once
      const promises = [
        props.fetchLocations(),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before setting 'initRequestsComplete' to true. We use
    // this to make sure we have all of the neccesary data before generating our map.
    props.fetchLocations();
    props.fetchPLCs();
    oneSecondInterval();

    return () => {
      clearTimeout(oneSecondIntervalId);
    };
  }, []);

  const locationEquipment1 = props.locations?.locations?.[1]?.equipment;
  if (!locationEquipment1) { return <LoadingScreen />; }

  const useCelsius = props.settings?.settings?.useCelsius;

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label={language.pumps}>
            <InfoControlRow>
              <MobileStatTable
                stats={[
                  {
                    description: language.dischargeWaterTemperature,
                    label: locationEquipment1?.Other?.ioValues?.[useCelsius ? 'discharge_water_temperature_alternate' : 'discharge_water_temperature']?.ioValueType?.units,
                    stat: locationEquipment1?.Other?.ioValues?.[useCelsius ? 'discharge_water_temperature_alternate' : 'discharge_water_temperature']?.value,
                  },
                  {
                    description: language.backPressure,
                    label: locationEquipment1?.Other?.ioValues?.back_pressure?.ioValueType?.units,
                    stat: locationEquipment1?.Other?.ioValues?.back_pressure?.value,
                  },
                  {
                    description: language.dischargeWaterPressure,
                    label: locationEquipment1?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units,
                    stat: locationEquipment1?.Other?.ioValues?.discharge_water_pressure?.value,
                  },
                  {
                    // description: language.waterFlow,
                    description: language.flowMeter,
                    label: locationEquipment1?.Other?.ioValues?.water_flow?.ioValueType?.units,
                    stat: locationEquipment1?.Other?.ioValues?.water_flow?.value,
                  },
                ]}
              />
              <ValvePID
                title="CV1 PID"
                data={locationEquipment1?.CV1}
                pidData={locationEquipment1?.CV1_PID}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoManual: 'auto_manual',
                  autoSetpoint: 'hill_auto_setpoint',
                  autoBackSetpoint: 'back_auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  hillBack: 'hill_back',
                }}
                readValues={[0, 1, 1]}
              />
              <div css={css`display: flex; flex-direction: column; align-items: center; @media only screen and (max-width: ${theme.mobileBreakpoint}px) { flex-direction: row; }`}>
                <AlarmReset
                  alarmData={locationEquipment1?.Other}
                  setIOValue={props.setIOValue}
                  buttonText={language.reset}
                  title={language.modbusReset}
                  alarmKeys={{
                    masterAlarm: 'modbus_error',
                    masterAlarmReset: 'modbus_reset',
                  }}
                  resetWriteValue={1}
                  buttonHeight="25px"
                />
                <AlarmReset
                  alarmData={locationEquipment1?.Other}
                  setIOValue={props.setIOValue}
                  buttonText={language.reset}
                  title={language.alarmReset}
                  resetWriteValue={1}
                  buttonHeight="25px"
                  margin="0px 5px 5px 0px"
                />
              </div>
              <div css={css`display: flex; flex-direction: column; align-items: center; @media only screen and (max-width: ${theme.mobileBreakpoint}px) { flex-direction: row; }`}>
                <Info
                  title={language.instrumentAir}
                  statistic={locationEquipment1?.IA?.ioValues?.low_air_pressure?.value === 0 ? language.lowAirPressure : 'Normal'}
                  color={locationEquipment1?.IA?.ioValues?.low_air_pressure?.value === 0 ? theme.alarm : theme.onGreen}
                />
                <Info
                  img={PLC}
                  title={language.plcStatus}
                  statistic={PLCStateRouter(props.plcs?.plcs?.[1])}
                  color={PLCColorRouter(props.plcs?.plcs?.[1], theme)}
                  margin="0px 5px 5px 0px"
                />
              </div>
              <Alarms
                title={language.generalAlarms}
                ioValues={locationEquipment1?.Other?.ioValues}
              />
              <MultiInfo
                subtitles={[language.voltage, language.current, language.drybulb, 'R.H', language.wetbulb]}
                statistics={[
                  locationEquipment1?.Other?.ioValues?.voltage?.value,
                  locationEquipment1?.Other?.ioValues?.current?.value,
                  locationEquipment1?.WS?.ioValues?.[useCelsius ? 'drybulb_alternate' : 'drybulb']?.value,
                  locationEquipment1?.WS?.ioValues?.relative_humidity?.value,
                  locationEquipment1?.WS?.ioValues?.[useCelsius ? 'wetbulb_alternate' : 'wetbulb']?.value,
                ]}
                labels={[
                  locationEquipment1?.Other?.ioValues?.voltage?.ioValueType?.units,
                  locationEquipment1?.Other?.ioValues?.current?.ioValueType?.units,
                  locationEquipment1?.WS?.ioValues?.[useCelsius ? 'drybulb_alternate' : 'drybulb']?.ioValueType?.units,
                  locationEquipment1?.WS?.ioValues?.relative_humidity?.ioValueType?.units,
                  locationEquipment1?.WS?.ioValues?.[useCelsius ? 'wetbulb_alternate' : 'wetbulb']?.ioValueType?.units,
                ]}
                precisions={[0, 0, 1, 0, 1]}
                ids={[
                  locationEquipment1?.Other?.ioValues?.voltage?.id,
                  locationEquipment1?.Other?.ioValues?.current?.id,
                  locationEquipment1?.WS?.ioValues?.[useCelsius ? 'drybulb_alternate' : 'drybulb']?.id,
                  locationEquipment1?.WS?.ioValues?.relative_humidity?.id,
                  locationEquipment1?.WS?.ioValues?.[useCelsius ? 'wetbulb_alternate' : 'wetbulb']?.id,
                ]}
              />
              {/* <MultiInfo
                subtitles={['Voltage', 'Current']}
                statistics={[
                  locationEquipment1?.Other?.ioValues?.voltage?.value,
                  locationEquipment1?.Other?.ioValues?.current?.value,
                ]}
                labels={[
                  locationEquipment1?.Other?.ioValues?.voltage?.ioValueType?.units,
                  locationEquipment1?.Other?.ioValues?.current?.ioValueType?.units,
                ]}
                precisions={[0, 0]}
                ids={[
                  locationEquipment1?.Other?.ioValues?.voltage?.id,
                  locationEquipment1?.Other?.ioValues?.current?.id,
                ]}
              /> */}
              <InfoButton
                title={language.tripVolume}
                statistic={locationEquipment1?.FT?.ioValues?.trip_volume?.value}
                label={locationEquipment1?.FT?.ioValues?.trip_volume?.ioValueType?.units}
                precision={0}
                buttonWriteId={locationEquipment1?.FT?.ioValues?.trip_volume_reset?.id}
                buttonText={language.reset}
                setIOValue={props.setIOValue}
              />
              <InfoButton
                title={language.totalVolume}
                statistic={locationEquipment1?.FT?.ioValues?.total_volume?.value}
                label={locationEquipment1?.FT?.ioValues?.total_volume?.ioValueType?.units}
                precision={0}
                buttonWriteId={locationEquipment1?.FT?.ioValues?.total_volume_reset?.id}
                buttonText={language.reset}
                setIOValue={props.setIOValue}
              />
            </InfoControlRow>
            <PumpsRowCustom
              height="80px"
              styles="padding-top: 10px;"
            >
              <div css={css`flex: 1; height: 100%;`} />
              <PipePlaceholder pipeAbove="right" />
              <PipePlaceholder
                horizontalAbove
              >
                <Statistic
                  border
                  label={locationEquipment1?.Other?.ioValues?.[useCelsius ? 'discharge_water_temperature_alternate' : 'discharge_water_temperature']?.ioValueType?.units}
                  statistic={locationEquipment1?.Other?.ioValues?.[useCelsius ? 'discharge_water_temperature_alternate' : 'discharge_water_temperature']?.value}
                />
                <Pipe horizontal />
                <Statistic
                  border
                  label={locationEquipment1?.Other?.ioValues?.back_pressure?.ioValueType?.units}
                  statistic={locationEquipment1?.Other?.ioValues?.back_pressure?.value}
                />
              </PipePlaceholder>
              <ValveConnect
                percent
                readOnly
                reverseValve
                showOnMobile
                binary={false}
                IOValueKeys={{
                  position: 'position',
                }}
                percentTop="47px"
                percentLeft="4px"
                horizontal="above"
                nameTop="-30px"
                nameLeft="8px"
                valveData={{ ...locationEquipment1?.CV1, name: 'CV1' }}
                setIOValue={props.setIOValue}
              />
              <div css={[css`height: 100%; width: fit-content; display: flex; justify-content: flex-start;`, styles.hiddenMobile]}>
                <div css={css`display: flex; width: 100%;`}>
                  <Statistic
                    border
                    label={locationEquipment1?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units}
                    statistic={locationEquipment1?.Other?.ioValues?.discharge_water_pressure?.value}
                  />
                  <Statistic
                    border
                    label={locationEquipment1?.Other?.ioValues?.water_flow?.ioValueType?.units}
                    statistic={locationEquipment1?.Other?.ioValues?.water_flow?.value}
                  />
                  <Pipe horizontal capAfter />
                </div>
              </div>
            </PumpsRowCustom>

            <PumpsRowCustom height="140px">
              {/* <div css={[css`height: 100%; width: 80px; display: flex; align-items: flex-end;`, styles.hiddenMobile]}>
                <Pipe horizontal capBefore capLabel={language.fromRiver} capLabelTop="-30px" capLabelLeft="5px" />
                <Statistic
                  float
                  border
                  label={locationEquipment1?.Other?.ioValues?.suction_pressure?.ioValueType?.units}
                  statistic={locationEquipment1?.Other?.ioValues?.suction_pressure?.value}
                  alertText={locationEquipment1?.Other?.ioValues.low_suction_pressure?.value ? language.lowSuctionPressure : null}
                  alertTop="4px"
                  alertLeft="40px"
                />
              </div> */}
              <div css={css`display: flex; flex-direction: column; width: 80px; height: 100%;`}>
                <ValveConnect
                  binary
                  percent
                  readOnly
                  showOnMobile
                  minWidth="80px"
                  percentTop="50px"
                  percentLeft="-15px"
                  nameTop="-35px"
                  nameLeft="-10px"
                  pipeAbove="right"
                  valveStateKeys={{
                    0: language.closed,
                    1: language.open,
                  }}
                  valveStateColorKeys={{
                    0: theme.offRed,
                    1: theme.onGreen,
                  }}
                  IOValueKeys={{
                    opened: 'opened_closed',
                    closed: 'opened_closed',
                  }}
                  readValues={[0, 1]}
                  greenImageKey={language.opened}
                  yellowImageKey={language.inTravel}
                  valveData={{ ...locationEquipment1?.BV, name: 'BV' }}
                  setIOValue={props.setIOValue}
                />
                <div css={[css`height: 50px;`, styles.hiddenMobile]} />
              </div>
              <Pump
                percent
                pipeAbove="middle"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment1?.P1}
                otherData={locationEquipment1?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                // statuses={[
                //   {
                //     id: locationEquipment1.P1?.ioValues?.status?.id,
                //     status: locationEquipment1.P1?.ioValues?.status?.value,
                //     statusKey: pumpMainStatusKey,
                //     statusColorKey: pumpMainStatusColorKey,
                //   },
                // ]}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                }}
                writeValues={[1, 1]}
                // readValues={[1, 0]}
                alarms={[
                  locationEquipment1.P1?.ioValues?.master_alarm,
                  locationEquipment1.P1?.ioValues?.low_discharge_pressure,
                  locationEquipment1.P1?.ioValues?.low_amps,
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.P1?.ioValues?.current?.value,
                    label: locationEquipment1?.P1?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P1?.ioValues?.run_time?.value,
                    label: locationEquipment1?.P1?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment1?.P2}
                otherData={locationEquipment1?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                }}
                writeValues={[1, 1]}
                // readValues={[1, 0]}
                alarms={[
                  locationEquipment1.P2?.ioValues?.master_alarm,
                  locationEquipment1.P2?.ioValues?.low_discharge_pressure,
                  locationEquipment1.P2?.ioValues?.low_amps,
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.P2?.ioValues?.current?.value,
                    label: locationEquipment1?.P2?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P2?.ioValues?.run_time?.value,
                    label: locationEquipment1?.P2?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment1?.P3}
                otherData={locationEquipment1?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                }}
                writeValues={[1, 1]}
                alarms={[
                  locationEquipment1.P3?.ioValues?.master_alarm,
                  locationEquipment1.P3?.ioValues?.low_discharge_pressure,
                  locationEquipment1.P3?.ioValues?.low_amps,
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.P3?.ioValues?.current?.value,
                    label: locationEquipment1?.P3?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P3?.ioValues?.run_time?.value,
                    label: locationEquipment1?.P3?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
              />
              <div css={css`display: flex; flex-direction: column; width: 80px; height: 100%;`}>
                <ValveConnect
                  binary
                  percent
                  readOnly
                  showOnMobile
                  minWidth="80px"
                  pipeAbove="left"
                  percentTop="50px"
                  percentLeft="-15px"
                  nameTop="-35px"
                  nameLeft="-10px"
                  valveStateKeys={{
                    0: language.closed,
                    1: language.open,
                  }}
                  valveStateColorKeys={{
                    0: theme.offRed,
                    1: theme.onGreen,
                  }}
                  IOValueKeys={{
                    opened: 'closed_opened',
                    closed: 'closed_opened',
                  }}
                  readValues={[1, 0]}
                  greenImageKey={language.opened}
                  yellowImageKey={language.inTravel}
                  valveData={{ ...locationEquipment1?.DV, name: 'DV' }}
                  setIOValue={props.setIOValue}
                />
                <div css={[css`height: 50px;`, styles.hiddenMobile]} />
              </div>
            </PumpsRowCustom>
            <PumpsRowCustom height="40px">
              <Pond
                color={theme.pipeColors.water}
                alertText={locationEquipment1?.Other?.ioValues?.low_water_level?.value ? language.lowWaterLevel : ''}
                alertTop="10px"
                alertLeft="20px"
              >
                <Statistic
                  border
                  statistic={locationEquipment1?.Other?.ioValues?.water_level?.value}
                  label={locationEquipment1?.Other?.ioValues?.water_level?.ioValueType?.units}
                />
              </Pond>
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label={language.compressors}>
            <InfoControlRow>
              <MobileStatTable
                stats={[
                  {
                    description: language.flowMeter,
                    label: locationEquipment1?.Other?.ioValues?.air_flow?.ioValueType?.units,
                    stat: locationEquipment1?.Other?.ioValues?.air_flow?.value,
                  },
                  {
                    description: language.dischargeAirTemperature,
                    label: locationEquipment1?.Other?.ioValues?.[useCelsius ? 'discharge_air_temperature_alternate' : 'discharge_air_temperature']?.ioValueType?.units,
                    stat: locationEquipment1?.Other?.ioValues?.[useCelsius ? 'discharge_air_temperature_alternate' : 'discharge_air_temperature']?.value,
                  },
                  {
                    description: language.dischargeAirPressure,
                    label: locationEquipment1?.Other?.ioValues?.discharge_air_pressure?.ioValueType?.units,
                    stat: locationEquipment1?.Other?.ioValues?.discharge_air_pressure?.value,
                  },
                ]}
              />
            </InfoControlRow>
            <PumpsRowCustom height="140px">
              <Pump
                percent
                setIOValue={props.setIOValue}
                pumpData={locationEquipment1?.CP1}
                otherData={locationEquipment1?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.offRed}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                }}
                writeValues={[1, 1]}
                stats={[
                  {
                    stat: locationEquipment1?.CP1?.ioValues?.run_time?.value,
                    label: locationEquipment1?.CP1?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
              />
              <Compressor
                pipeAbove="right"
                pipeBelow="right"
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.water}
                controlPositionTop="-50px"
                controlPositionLeft="60px"
                compressorImg={GreenCompressor}
                compressorImgGlow={GreenCompressorGlow}
                compressorData={locationEquipment1?.C1}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                stats={[
                  {
                    stat: locationEquipment1?.C1?.ioValues?.current?.value,
                    label: locationEquipment1?.C1?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.C1?.ioValues?.run_time?.value,
                    label: locationEquipment1?.C1?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
                alarms={[
                  locationEquipment1.C1?.ioValues?.low_coolant_pressure,
                ]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.offRed,
                  1: theme.onGreen,
                }}
              />
              <Compressor
                pipeAbove="middle"
                pipeBelow="middle"
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.water}
                controlPositionTop="-50px"
                controlPositionLeft="60px"
                compressorImg={GreenCompressor}
                compressorImgGlow={GreenCompressorGlow}
                compressorData={locationEquipment1?.C2}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                stats={[
                  {
                    stat: locationEquipment1?.C2?.ioValues?.current?.value,
                    label: locationEquipment1?.C2?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.C2?.ioValues?.run_time?.value,
                    label: locationEquipment1?.C2?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
                alarms={[
                  locationEquipment1.C2?.ioValues?.low_coolant_pressure,
                ]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.offRed,
                  1: theme.onGreen,
                }}
              />
              <div css={[css`height: 100%; flex: 1; display: flex; flex-direction: column;`, styles.hiddenMobile]}>
                <div css={css`display: flex; width: 100%; height: 100%;`}>
                  <Statistic
                    border
                    label={locationEquipment1?.Other?.ioValues?.air_flow?.ioValueType?.units}
                    statistic={locationEquipment1?.Other?.ioValues?.air_flow?.value}
                  />
                  <Pipe color={theme.pipeColors.air} horizontal />
                  <Statistic
                    border
                    label={locationEquipment1?.Other?.ioValues?.[useCelsius ? 'discharge_air_temperature_alternate' : 'discharge_air_temperature']?.ioValueType?.units}
                    statistic={locationEquipment1?.Other?.ioValues?.[useCelsius ? 'discharge_air_temperature_alternate' : 'discharge_air_temperature']?.value}
                  />
                  <Pipe color={theme.pipeColors.air} horizontal />
                  <Statistic
                    border
                    label={locationEquipment1?.Other?.ioValues?.discharge_air_pressure?.ioValueType?.units}
                    statistic={locationEquipment1?.Other?.ioValues?.discharge_air_pressure?.value}
                  />
                  <Pipe color={theme.pipeColors.air} horizontal capAfter />
                </div>
                <div css={css`display: flex; width: 100%; height: 100%; align-items: flex-end;`}>
                  <Pipe color={theme.pipeColors.water} horizontal capAfter capLabel="From CP1" capLabelTop="-25px" capLabelLeft="-80px" />
                </div>
              </div>
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
};

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      background-color: white;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        min-height: fit-content;
        max-height: fit-content;
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
      }
      scrollbar-width: none; /* Firefox */
    `,
  };
};

EquipmentScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,
};

EquipmentScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
  settings: state.settings,
});

export default connect(mapStateToProps, { fetchLocations, setIOValue, fetchPLCs })(EquipmentScreen);
