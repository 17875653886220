// Quebec Factory Configuration File
// Template Created By: Trevor Colby
// 9/21/2021

// Values to fine tune for a new mountain setup
// Used by: Tracker Screen & Overview Screen & Login Screen
// - initialScreen: The path to the first screen shown by default after logging in. (Usually the Overview Screen '/overview' available routes defined in components/Routes.jsx)
// - center: Center of the view, shouldn't change from mountain to mountain (included just in case there is a mountain with special configuration needs).
// - defaultCenterPoint: The coordinates of the default center location of the map. Only used if there are no hydrants present.
//                       You can get this value by using the TrackerMapper tool https://jolavb.github.io/Sno_Demo to place a hydrant at the center of the desired resort, exporting a GeoJson of
//                       the hydrants (make sure to uncheck the exclude orphans options), opening the exported file in a text editor, and copying the coordinates of the hydrant.
//                       (format: [number, number])
// - radius, radius2: Hydrant icon size on map. Should both be the same value unless an irregular hydrant shape is desired.
// - strokeWidth: Width of the default hydrant border (black).
// - noteStrokeWidth: Width of the hydrant border when there is an open note (purple).
// - minZoomLimit: How far you can zoom out (recommended to use config details option in bottom right tracker options to find this value)
// - maxZoomLimit: How far you can zoom in (recommended to use config details option in bottom right tracker options to find this value)
// - initialZoom: Starting zoom level, should be between min/max zoom.
//                You can find this quickly by zooming to the desired level and clicking the config details option in the bottom right corner tracker controls.
//                The currrent zoom will be printed in the browser's developer console.
// - padding: Tweaks the map's view center to accomdate for outside elements that are covering viewspace
//            (format: [200, 200, 200, 200] but often set to undefined because the extra space around the outside is not often needed)
// - extentFactors: Variable to control how far user can scroll, each value is used as a multiplier so use decimals for division.
//                  (format: [6.0, 6.0, 6.0, 6.0] but often set to undefined if hydrants will be added later to prevent extent issues i.e hydrants being off the edge of the map)
// - rotationFactor: Used to orient the mountain.
//                   Rotate the mountain to the desired position and then click the config details option in the bottom right corner tracker controls.
//                   The rotation will be printed in the browser's developer console.
// - labelZoom: Zoom level at which labels are shown.
// - trackerEquipmentZoom: Zoom level at which tracker equipment such as weather station icons/overlays are auto toggled.
// - trackerEquipmentIconScale: Scale factor for sizing the tracker equipment icons.
// - mapEditRole: User role that dictates if map edit options are displayed for the tracker and various other zone/gun options are displayed in settings.
// - developerRole: User role that dictates if specific plant controls requiring developer access are rendered.
// - devURL/prodURL: Base url for the backend api (NOTE: this needs to be updated appropriately for local deployments).
// - jawgToken: Token to access the free jawg api that gives us elevations for hydrants.
//              (NOTE: Eventually we will want a few of these in circulation to prevent having too many requests)

const configuration = {
  initialScreen: '/overview',
  center: [0, 0],
  defaultCenterPoint: [-106.82225115970424, 39.17050763207959],
  radius: 50,
  radius2: 50,
  strokeWidth: 8,
  noteStrokeWidth: 20,
  minZoomLimit: 14,
  maxZoomLimit: 21,
  initialZoom: 16.5,
  padding: undefined,
  extentFactors: undefined,
  rotationFactor: 4.0894483020532135,
  labelZoom: 16,
  trackerEquipmentZoom: 17.5,
  trackerEquipmentIconScale: 0.75,
  mapEditRole: 'MapEditor',
  developerRole: 'Developer',
  devURL: 'https://gleneden-api.isno.io/api/v1.0',
  prodURL: 'https://gleneden-api.isno.io/api/v1.0',
  jawgToken: 'TQ3T3df0pxRwdI4DQeXRRiHMYyVUPdLo69Ml4DFp0kn5oIwDXNFOHP6ZlWbRZIGS',
};

export default configuration;
