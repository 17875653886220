import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { ReactComponent as LoginSVG } from 'isno/lib/static/images/login.svg';

const LoginButton = (props) => {
  const theme = useTheme();
  const styles = stylesFromTheme(theme);

  const [animation, setAnimation] = useState(props.animate);

  useEffect(() => {
    setAnimation(props.animate);
  }, [props]);

  return (
    <div css={styles.loginButtonContainer}>
      <LoginSVG css={animation ? styles.svgAnimation : styles.svgLoginButton} />
      <div css={animation ? styles.loginButtonTextAuthenticate : styles.loginButtonText}>{animation ? 'Authenticating' : props.text}</div>
    </div>
  );
};

LoginButton.propTypes = {
  animate: PropTypes.bool.isRequired,
  text: PropTypes.string,
};

LoginButton.defaultProps = {
  text: 'Login',
};

const stylesFromTheme = (theme) => {
  return {
    loginButtonContainer: css`
        display: flex; 
        align-items: center;
        justify-content: center;
        position: relative;
        stroke: inherit; 
        :focus {
          outline: none;
          stroke: #59CBEE;
        };
    `,
    loginButtonText: css`
        position: absolute;
        top: 50%;
        left: 50%;
        color: white;
        margin-top: -6.5px;
        margin-left: -15px;
        pointer-events: none;
        @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
          margin-top: -10px;
          margin-left: -18.5px;
        }
    `,
    loginButtonTextAuthenticate: css`
        position: absolute;
        top: 50%;
        left: 50%;
        color: white;
        margin-top: -6.5px;
        margin-left: -40.5px;
        pointer-events: none;
        @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
          margin-top: -10px;
          margin-left: -44px;
        }
    `,
    svgLoginButton: css`
        fill: none;
        fill-opacity:1;
        stroke-linecap:round;
        stroke-miterlimit:4;
        stroke-dasharray:none;
        stroke-opacity:1;
        stroke: inherit;
        stroke-width: 0.3px;
        :focus {
          outline: none;
          stroke: #59CBEE;
        };
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: white;
        }
        ::-ms-input-placeholder { /* Microsoft Edge */
          color: white;
        }
        :hover {
          stroke: #59CBEE;
          fill: rgba(80, 80, 82, 0.4);
        }
    `,
    svgAnimation: css`
        fill: rgba(80, 80, 82, 0.4);
        stroke-linecap:round;
        stroke-miterlimit:4;
        stroke-dasharray: 185;
        stroke-opacity:1;
        stroke: #59CBEE;
        stroke-width: 0.3px;
        animation-name: auth;
        animation-duration: 3s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        @keyframes auth {
            0% { 
                stroke-dashoffset: 185; 
                fill-opacity: 0.1;
            }
            50% {  
                stroke-dashoffset: 0; 
                fill-opacity: 0.9;
            }
            100% {  
                stroke-dashoffset: -185; 
                fill-opacity: 0.1;
            }
        }
    `,
  };
};

export default LoginButton;
